export const keyboardLetters = [
  ['A', 'Á', 'E', 'É', 'I', 'Í', 'O', 'Ó', 'U', 'Ú'],
  ['B', 'C', 'D', 'F', 'G', 'H', 'L', 'M', 'N'],
  ['P','R', 'S', 'T','V'],
]

export const letters = [
  'A',
  'Á',
  'B',
  'C',
  'D',
  'E',
  'É',
  'F',
  'G',
  'H',
  'I',
  'Í',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'Ó',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'Ú',
  'V',
  'X',
  'Y',
  'Z',
]

export const status = {
  green: 'green',
  yellow: 'yellow',
  gray: 'gray',
  unguessed: 'unguessed',
}

export const dictionary = {
  HowToPlay: 'Treoracha',
  SixGuessesLeft: 'Gheobhaidh tú 6 iarracht leis an fhocal cheart a thomhas.',
  GuessAnyValidWord: 'Glactar le mórchuid na bhfocal bailí Gaeilge. Ní ghlactar le hainmneacha dílse.',
  AfterEachGuessLetterWillTurnDifferentColour: 'I ndiaidh gach tomhas a dhéanann tú, cuirfear dath glas, buí nó liath ar na litreacha a chuir tú isteach.',
  CorrectLetterCorrectSpot: 'An litir cheart, san áit cheart',
  CorrectLetterWrongSpot: 'An litir cheart, san áit mhícheart',
  WrongLetter: 'An litir mhícheart',
  ProjectIsOpenSource: 'Tá an tionscnamh seo ar fáil ar fhoinse fhoscailte.',
  PlayAgain: 'Imir Arís',
  CurrentStreak: 'buanna gan bhriseadh',
  LongestStreak: 'sraith is faide',
  Oops: 'Úps!',
  TheWordWas: 'Ba é an focal nó:',
  DarkMode: 'Mód dorcha',
  IfYouAreEnjoyingThisGame: 'Má tá tú ag baint sult as an chluiche seo, tabhair tacaíocht domh trí',
  BuyMeACoffee: 'phionta Guinness a cheannacht domh',
  Enter: 'Tomhas',
  ResultsCopiedToClipboard: 'Tá na torthaí cóipeáilte chuig an ghearrthaisce',
  ShareTheResults: 'Roinn na torthaí',
  DownloadAppToPhone: 'Íoslódáil an aip seo chuig d\'fhón',
  PlayTheGameOffline: 'Imir an cluiche as líne',
  UseLessData: 'Laghdaigh an méid sonraí a úsáidtear don chluiche',
  OneClickAccessFromHomeScreen: 'Aimsigh an cluiche le clic amháin',
  DownloadNow: 'íoslódáil anois',
  About: 'Eolas',
  AndroidChromeOnly: 'Oibríonn ar Android & Chrome amháin',
  Statistics: 'Staitisticí',
  Played: 'cluiche imeartha',
  Wins: 'buanna',
  Share: 'Roinn',
  ModeMessage: `Tá tú ag imirt sa MHÓD LAETHÚIL. Sa mhód seo, tugtar an tomhas céanna do gach imreoir gach tréimhse 24 uair, agus thig leat do thorthaí a roinnt ar líne. 
  Más mian leat níos mó ná cluiche amháin a imirt sa lá, thig leat úsáid a bhaint as an MHÓD CLEACHTAIDH. 
  Sa mhód cleachtaidh thig leat imirt chomh minic agus is mian leat, ach ní thugtar rogha duit do thorthaí a roinnt ar líne.`,
  DayMode: 'Mód Laethúil',
  PracticeMode: 'Mód Cleachtaidh',
  CheadFoclachEile: 'An chéad FOCLACH eile',
  PracticeModeMessage: `Tá tú ag imirt sa MHÓD CLEACHTAIDH. Sa mhód seo ní chuirtear na buanna leis an scór agus níl rogha ann na torthaí a roinnt ar líne. 
  Nuair a imrítear sa MHÓD LAETHÚIL tugtar an tomhas céanna gach lá do gach imreoir, cuirtear na buanna leis an scór agus bíonn deis agat do thorthaí a roinnt ar líne.`,
  distribution: 'Dáileadh na dTomhas'
}
