const answers = [
  'duine',
  'abair',
  'maith',
  'faigh',
  'téigh',
  'nuair',
  'amach',
  'ceann',
  'caith',
  'chomh',
  'chuig',
  'obair',
  'taobh',
  'anois',
  'céile',
  'teach',
  'ceart',
  'scéal',
  'roimh',
  'ceist',
  'ansin',
  'dóigh',
  'pobal',
  'scoil',
  'roinn',
  'úsáid',
  'leith',
  'anseo',
  'oíche',
  'baile',
  'lucht',
  'cúrsa',
  'eolas',
  'imigh',
  'caint',
  'ábhar',
  'féach',
  'siúil',
  'éigin',
  'buail',
  'riamh',
  'focal',
  'beirt',
  'measc',
  'clois',
  'cónaí',
  'thart',
  'minic',
  'anuas',
  'ionad',
  'uisce',
  'geall',
  'cúpla',
  'cúirt',
  'doras',
  'oifig',
  'córas',
  'mórán',
  'sásta',
  'inniu',
  'suigh',
  'breis',
  'sráid',
  'caill',
  'gearr',
  'áfach',
  'creid',
  'luath',
  'grúpa',
  'fadhb',
  'scéim',
  'seans',
  'bocht',
  'thiar',
  'tuill',
  'lacha',
  'gaoth',
  'dream',
  'cumas',
  'tráth',
  'clann',
  'stair',
  'cúram',
  'athrú',
  'toisc',
  'crann',
  'páirt',
  'cloch',
  'solas',
  'foirm',
  'litir',
  'plean',
  'páirc',
  'léann',
  'rogha',
  'leath',
  'féile',
  'uasal',
  'conas',
  'léigh',
  'grian',
  'dócha',
  'píosa',
  'thíos',
  'fóill',
  'turas',
  'luigh',
  'beith',
  'riail',
  'fógra',
  'thuas',
  'beart',
  'léamh',
  'deara',
  'siopa',
  'leaba',
  'deich',
  'dearg',
  'eagla',
  'baint',
  'garda',
  'dráma',
  'fiche',
  'luach',
  'réidh',
  'neart',
  'triúr',
  'aonar',
  'marbh',
  'deoch',
  'greim',
  'fosta',
  'dalta',
  'droim',
  'troid',
  'bhuel',
  'ócáid',
  'tuath',
  'brath',
  'ciall',
  'airde',
  'foras',
  'spéis',
  'iníon',
  'spórt',
  'gnáth',
  'bronn',
  'socrú',
  'bláth',
  'thall',
  'íseal',
  'glúin',
  'aigne',
  'earra',
  'aonad',
  'duais',
  'balla',
  'meall',
  'aníos',
  'bille',
  'nocht',
  'aidhm',
  'gáire',
  'spéir',
  'breac',
  'easpa',
  'galar',
  'gasúr',
  'ciúin',
  'bosca',
  'theas',
  'urlár',
  'iomaí',
  'teann',
  'croch',
  'fórsa',
  'madra',
  'laoch',
  'leasú',
  'cósta',
  'cruth',
  'áthas',
  'anonn',
  'téacs',
  'feirm',
  'halla',
  'araon',
  'gléas',
  'miste',
  'bréag',
  'gunna',
  'caora',
  'aniar',
  'dearc',
  'moill',
  'snámh',
  'cnámh',
  'géill',
  'caoin',
  'druga',
  'cárta',
  'achar',
  'cúige',
  'ionas',
  'báigh',
  'sruth',
  'feoil',
  'dílis',
  'locht',
  'éadan',
  'rúnaí',
  'staid',
  'éasca',
  'scaip',
  'aiste',
  'scoth',
  'druid',
  'ciste',
  'téama',
  'tairg',
  'ocras',
  'maígh',
  'deoir',
  'seinn',
  'sonra',
  'tobar',
  'béile',
  'coill',
  'óstán',
  'maoin',
  'achan',
  'náire',
  'bunús',
  'anall',
  'gnách',
  'scáth',
  'tréig',
  'méadú',
  'craol',
  'íomhá',
  'othar',
  'aicme',
  'fuaim',
  'gairm',
  'sáigh',
  'tirim',
  'báire',
  'ceird',
  'garbh',
  'geata',
  'liath',
  'maide',
  'naofa',
  'uaigh',
  'cluin',
  'dabht',
  'braon',
  'uafás',
  'tábla',
  'tréan',
  'banna',
  'cairt',
  'cluas',
  'binse',
  'anáil',
  'neamh',
  'nádúr',
  'fásta',
  'cosán',
  'ardán',
  'feadh',
  'fearg',
  'cúlra',
  'aisce',
  'peann',
  'srian',
  'aréir',
  'caite',
  'claon',
  'gorta',
  'fiach',
  'casta',
  'dlúth',
  'caoga',
  'míniú',
  'péire',
  'láigh',
  'rince',
  'barra',
  'cearc',
  'scuab',
  'práta',
  'naomh',
  'cleas',
  'ruaig',
  'guigh',
  'scoir',
  'armas',
  'fiáin',
  'peaca',
  'preab',
  'taise',
  'scála',
  'grinn',
  'brách',
  'onóir',
  'buile',
  'titim',
  'agóid',
  'sacar',
  'colún',
  'bréan',
  'teith',
  'eagar',
  'ríoga',
  'eolaí',
  'pósta',
  'abhus',
  'fuath',
  'ríomh',
  'díomá',
  'cupán',
  'buíon',
  'gleic',
  'éacht',
  'dligh',
  'scian',
  'fáisc',
  'pláta',
  'ithir',
  'iomad',
  'díbir',
  'scáil',
  'eitil',
  'feith',
  'tobac',
  'fionn',
  'crith',
  'teist',
  'bráid',
  'léine',
  'scata',
  'giota',
  'feall',
  'scair',
  'toirt',
  'sciob',
  'daite',
  'réigh',
  'cogar',
  'tanaí',
  'oibrí',
  'suite',
  'muise',
  'campa',
  'rúnda',
  'togha',
  'dúnta',
  'cléir',
  'gnéas',
  'gasta',
  'táirg',
  'allas',
  'sáile',
  'doirt',
  'dúlra',
  'tiubh',
  'finné',
  'botún',
  'albam',
  'píopa',
  'togra',
  'óráid',
  'blais',
  'seach',
  'neach',
  'faill',
  'milis',
  'tarbh',
  'múnla',
  'méara',
  'fíric',
  'buama',
  'baist',
  'crios',
  'leaid',
  'meath',
  'aosta',
  'seilg',
  'goill',
  'séimh',
  'scíth',
  'puinn',
  'rialú',
  'gadaí',
  'maise',
  'imirt',
  'ochtó',
  'craic',
  'olann',
  'súigh',
  'docht',
  'ailse',
  'canna',
  'faire',
  'caoch',
  'síoda',
  'eadar',
  'cumha',
  'caife',
  'sólás',
  'sáith',
  'ráfla',
  'cuach',
  'airne',
  'alcól',
  'luibh',
  'tnúth',
  'cearr',
  'tacht',
  'tuile',
  'beach',
  'éindí',
  'anoir',
  'sciar',
  'plána',
  'beola',
  'creat',
  'cróga',
  'mothú',
  'thusa',
  'aoibh',
  'sicín',
  'bearr',
  'féith',
  'ortha',
  'forás',
  'masla',
  'cónra',
  'trian',
  'dhein',
  'ceilt',
  'tosaí',
  'staon',
  'goile',
  'tuama',
  'uacht',
  'peata',
  'laige',
  'stoca',
  'fráma',
  'scaif',
  'draoi',
  'beann',
  'glóir',
  'teilg',
  'péist',
  'luasc',
  'cathú',
  'séala',
  'racht',
  'ídigh',
  'snáth',
  'uaimh',
  'casóg',
  'oilte',
  'smior',
  'cúisí',
  'císte',
  'ireas',
  'tarlú',
  'balbh',
  'cothú',
  'púdar',
  'sonas',
  'seift',
  'beoir',
  'lampa',
  'meaig',
  'caifé',
  'éirim',
  'strae',
  'mamaí',
  'umhal',
  'mionn',
  'cíoch',
  'coisc',
  'tuata',
  'téamh',
  'inmhe',
  'dreap',
  'bheir',
  'uatha',
  'seilf',
  'leoga',
  'nócha',
  'gonta',
  'iomrá',
  'dreas',
  'déirc',
  'adamh',
  'díobh',
  'gread',
  'leann',
  'olcas',
  'urnaí',
  'gortú',
  'cearn',
  'iolra',
  'gnúis',
  'péint',
  'staic',
  'buair',
  'tocht',
  'fostú',
  'urrús',
  'leamh',
  'dóite',
  'toise',
  'daidí',
  'gealt',
  'tonna',
  'focla',
  'saint',
  'grean',
  'aoire',
  'néata',
  'ordóg',
  'liopa',
  'finne',
  'úrnua',
  'tréad',
  'céimí',
  'creim',
  'spota',
  'donas',
  'coirm',
  'camán',
  'leomh',
  'chóir',
  'idéal',
  'dínit',
  'cipín',
  'saill',
  'fócas',
  'cófra',
  'aspal',
  'pálás',
  'clais',
  'slios',
  'fónta',
  'copar',
  'aicíd',
  'spíon',
  'stuif',
  'giall',
  'coire',
  'diaga',
  'gráin',
  'báúil',
  'sodar',
  'modúl',
  'deasc',
  'deoin',
  'uaine',
  'buaic',
  'strus',
  'éigse',
  'putóg',
  'loisc',
  'líomh',
  'aneas',
  'clárú',
  'seoid',
  'daofa',
  'fásra',
  'druma',
  'ionga',
  'bogha',
  'tráta',
  'bacán',
  'tacar',
  'casúr',
  'rolla',
  'clúmh',
  'leasc',
  'líofa',
  'gréas',
  'slánú',
  'plúch',
  'dealg',
  'preas',
  'cuing',
  'allta',
  'corca',
  'iolar',
  'arann',
  'sféar',
  'fuaid',
  'dréim',
  'teibí',
  'gátar',
  'prioc',
  'sprid',
  'sméar',
  'gotha',
  'pluid',
  'colúr',
  'dáimh',
  'síniú',
  'lionn',
  'fáidh',
  'athrá',
  'frása',
  'goirt',
  'méith',
  'orgán',
  'solad',
  'matán',
  'sméid',
  'gabha',
  'bábóg',
  'cábán',
  'taisc',
  'tarna',
  'agair',
  'fiuch',
  'folús',
  'ribín',
  'súgán',
  'banbh',
  'stail',
  'cneas',
  'leaca',
  'thoir',
  'eagal',
  'cruas',
  'scóip',
  'próca',
  'craos',
  'faide',
  'cadás',
  'glinn',
  'plaic',
  'cailc',
  'luail',
  'corda',
  'nuige',
  'clúid',
  'áirid',
  'giúis',
  'éiric',
  'seoda',
  'cruit',
  'biúró',
  'chaon',
  'smear',
  'mótar',
  'fidil',
  'strac',
  'plota',
  'cuóta',
  'íosta',
  'clóca',
  'bligh',
  'gasra',
  'lúfar',
  'sáinn',
  'suirí',
  'geábh',
  'creig',
  'drong',
  'cábla',
  'tiúin',
  'rubar',
  'teora',
  'iarla',
  'ladar',
  'géire',
  'ceadú',
  'péine',
  'lagar',
  'tátal',
  'feann',
  'deilf',
  'macha',
  'oisre',
  'banda',
  'smúit',
  'bríce',
  'stéig',
  'cógas',
  'bagún',
  'gaois',
  'seasc',
  'darna',
  'scinn',
  'scriú',
  'eorna',
  'gríos',
  'bolta',
  'lasta',
  'áirse',
  'easna',
  'riasc',
  'punta',
  'iníor',
  'aclaí',
  'cráin',
  'coirt',
  'cunta',
  'tacaí',
  'treis',
  'scean',
  'trúpa',
  'ceirt',
  'céilí',
  'fogha',
  'spoch',
  'casla',
  'eagna',
  'gobán',
  'ispín',
  'úrach',
  'babaí',
  'seile',
  'théid',
  'feart',
  'uaill',
  'treas',
  'iaigh',
  'fisic',
  'scall',
  'ísliú',
  'cling',
  'gnaoi',
  'meirg',
  'liric',
  'seang',
  'fríth',
  'cealg',
  'ceilp',
  'galún',
  'codán',
  'trosc',
  'círín',
  'faomh',
  'leice',
  'ochtú',
  'dólás',
  'scrín',
  'sceon',
  'diail',
  'pianó',
  'scéin',
  'taosc',
  'flosc',
  'sobal',
  'crobh',
  'mánla',
  'déine',
  'próis',
  'tearc',
  'easóg',
  'bailí',
  'maróg',
  'goimh',
  'scolb',
  'sonrú',
  'mnaoi',
  'srann',
  'eitic',
  'báith',
  'tighe',
  'leite',
  'searr',
  'baoth',
  'calma',
  'fáilí',
  'crúca',
  'curfá',
  'slinn',
  'múnlú',
  'críon',
  'tarra',
  'fíoch',
  'buach',
  'corna',
  'barda',
  'éidir',
  'snigh',
  'beoga',
  'básta',
  'fuáil',
  'camas',
  'speal',
  'coisí',
  'sciúr',
  'tóraí',
  'díleá',
  'spalp',
  'ceant',
  'fánaí',
  'saobh',
  'bráth',
  'searg',
  'falla',
  'drúis',
  'slíoc',
  'gráig',
  'oibir',
  'falsa',
  'tréas',
  'radar',
  'oidhe',
  'breab',
  'matal',
  'baois',
  'maraí',
  'bácús',
  'eilit',
  'cnead',
  'drann',
  'scuad',
  'balún',
  'gaibh',
  'árach',
  'bulaí',
  'sanas',
  'guaim',
  'dusta',
  'cuain',
  'plimp',
  'ochón',
  'stáir',
  'iompú',
  'boige',
  'silín',
  'doire',
  'rangú',
  'léana',
  'canta',
  'báiní',
  'seort',
  'dalba',
  'searc',
  'casán',
  'toill',
  'trunc',
  'cumar',
  'análú',
  'scáin',
  'scine',
  'cuilt',
  'muirí',
  'trúig',
  'lacht',
  'cáith',
  'sotal',
  'múisc',
  'curaí',
  'buirg',
  'racán',
  'léimt',
  'bráca',
  'stróc',
  'lábán',
  'colpa',
  'ogham',
  'lonnú',
  'calóg',
  'céide',
  'damnú',
  'dlaoi',
  'eipic',
  'aibíd',
  'moing',
  'lorga',
  'bulla',
  'stáca',
  'pailm',
  'caise',
  'stiúg',
  'polla',
  'sleán',
  'muine',
  'fonsa',
  'pluma',
  'bólaí',
  'burla',
  'teasc',
  'uasta',
  'dílse',
  'draid',
]

export default answers
