/* eslint-disable */
const words = {
  gaeil: true,
  duine: true,
  abair: true,
  maith: true,
  faigh: true,
  téigh: true,
  nuair: true,
  amach: true,
  ceann: true,
  caith: true,
  chomh: true,
  chuig: true,
  obair: true,
  taobh: true,
  anois: true,
  céile: true,
  teach: true,
  ceart: true,
  scéal: true,
  roimh: true,
  ceist: true,
  ansin: true,
  forún: true,
  dóigh: true,
  pobal: true,
  scoil: true,
  roinn: true,
  úsáid: true,
  leith: true,
  anseo: true,
  oíche: true,
  baile: true,
  lucht: true,
  cúrsa: true,
  eolas: true,
  imigh: true,
  caint: true,
  ábhar: true,
  féach: true,
  siúil: true,
  éigin: true,
  buail: true,
  riamh: true,
  focal: true,
  beirt: true,
  measc: true,
  clois: true,
  cónaí: true,
  thart: true,
  minic: true,
  anuas: true,
  ionad: true,
  uisce: true,
  geall: true,
  cúpla: true,
  cúirt: true,
  doras: true,
  oifig: true,
  córas: true,
  mórán: true,
  sásta: true,
  inniu: true,
  suigh: true,
  breis: true,
  sráid: true,
  caill: true,
  gearr: true,
  áfach: true,
  creid: true,
  luath: true,
  grúpa: true,
  fadhb: true,
  scéim: true,
  easca: true,
  seans: true,
  bocht: true,
  thiar: true,
  tuill: true,
  uilig: true,
  lacha: true,
  gaoth: true,
  dream: true,
  cumas: true,
  tráth: true,
  clann: true,
  stair: true,
  cúram: true,
  athrú: true,
  toisc: true,
  crann: true,
  páirt: true,
  cloch: true,
  solas: true,
  foirm: true,
  litir: true,
  plean: true,
  páirc: true,
  léann: true,
  rogha: true,
  leath: true,
  féile: true,
  uasal: true,
  conas: true,
  léigh: true,
  grian: true,
  dócha: true,
  píosa: true,
  thíos: true,
  fóill: true,
  turas: true,
  luigh: true,
  beith: true,
  riail: true,
  fógra: true,
  thuas: true,
  beart: true,
  léamh: true,
  deara: true,
  siopa: true,
  leaba: true,
  deich: true,
  dearg: true,
  eagla: true,
  baint: true,
  garda: true,
  dráma: true,
  fiche: true,
  luach: true,
  réidh: true,
  neart: true,
  triúr: true,
  aonar: true,
  marbh: true,
  deoch: true,
  greim: true,
  fosta: true,
  dalta: true,
  droim: true,
  droch: true,
  troid: true,
  bhuel: true,
  ócáid: true,
  tuath: true,
  brath: true,
  ciall: true,
  airde: true,
  foras: true,
  spéis: true,
  iníon: true,
  spórt: true,
  gnáth: true,
  bronn: true,
  socrú: true,
  bláth: true,
  thall: true,
  íseal: true,
  glúin: true,
  aigne: true,
  earra: true,
  aonad: true,
  duais: true,
  balla: true,
  meall: true,
  aníos: true,
  bille: true,
  nocht: true,
  gáire: true,
  spéir: true,
  breac: true,
  easpa: true,
  galar: true,
  gasúr: true,
  ciúin: true,
  bosca: true,
  theas: true,
  urlár: true,
  iomaí: true,
  teann: true,
  croch: true,
  fórsa: true,
  madra: true,
  laoch: true,
  Brian: true,
  leasú: true,
  cósta: true,
  cruth: true,
  áthas: true,
  anonn: true,
  téacs: true,
  feirm: true,
  halla: true,
  araon: true,
  gléas: true,
  miste: true,
  bréag: true,
  gunna: true,
  caora: true,
  aniar: true,
  dearc: true,
  moill: true,
  snámh: true,
  cnámh: true,
  géill: true,
  caoin: true,
  druga: true,
  cárta: true,
  achar: true,
  cúige: true,
  ionas: true,
  báigh: true,
  sruth: true,
  feoil: true,
  dílis: true,
  locht: true,
  éadan: true,
  rúnaí: true,
  staid: true,
  éasca: true,
  scaip: true,
  aiste: true,
  scoth: true,
  druid: true,
  ciste: true,
  téama: true,
  tairg: true,
  ocras: true,
  maígh: true,
  deoir: true,
  seinn: true,
  sonra: true,
  tobar: true,
  béile: true,
  coill: true,
  óstán: true,
  maoin: true,
  achan: true,
  náire: true,
  bunús: true,
  anall: true,
  gnách: true,
  scáth: true,
  tréig: true,
  méadú: true,
  craol: true,
  íomhá: true,
  othar: true,
  aicme: true,
  fuaim: true,
  gairm: true,
  sáigh: true,
  tirim: true,
  báire: true,
  ceird: true,
  garbh: true,
  geata: true,
  liath: true,
  maide: true,
  naofa: true,
  uaigh: true,
  cluin: true,
  dabht: true,
  braon: true,
  uafás: true,
  tábla: true,
  tréan: true,
  banna: true,
  cairt: true,
  cluas: true,
  binse: true,
  anáil: true,
  neamh: true,
  nádúr: true,
  fásta: true,
  cosán: true,
  ardán: true,
  feadh: true,
  fearg: true,
  cúlra: true,
  aisce: true,
  peann: true,
  srian: true,
  aréir: true,
  caite: true,
  claon: true,
  gorta: true,
  fiach: true,
  casta: true,
  dlúth: true,
  caoga: true,
  míniú: true,
  péire: true,
  láigh: true,
  rince: true,
  barra: true,
  cearc: true,
  scuab: true,
  práta: true,
  naomh: true,
  cleas: true,
  ruaig: true,
  guigh: true,
  scoir: true,
  armas: true,
  fiáin: true,
  peaca: true,
  preab: true,
  taise: true,
  scála: true,
  Cáisc: true,
  grinn: true,
  brách: true,
  onóir: true,
  buile: true,
  titim: true,
  agóid: true,
  sacar: true,
  colún: true,
  bréan: true,
  teith: true,
  eagar: true,
  ríoga: true,
  eolaí: true,
  pósta: true,
  abhus: true,
  fuath: true,
  ríomh: true,
  Páras: true,
  díomá: true,
  cupán: true,
  buíon: true,
  gleic: true,
  éacht: true,
  dligh: true,
  scian: true,
  fáisc: true,
  pláta: true,
  ithir: true,
  iomad: true,
  díbir: true,
  scáil: true,
  eitil: true,
  feith: true,
  tobac: true,
  fionn: true,
  crith: true,
  teist: true,
  bráid: true,
  léine: true,
  scata: true,
  giota: true,
  feall: true,
  scair: true,
  toirt: true,
  sciob: true,
  daite: true,
  réigh: true,
  cogar: true,
  tanaí: true,
  oibrí: true,
  suite: true,
  muise: true,
  campa: true,
  rúnda: true,
  togha: true,
  dúnta: true,
  cléir: true,
  gnéas: true,
  gasta: true,
  táirg: true,
  allas: true,
  sáile: true,
  doirt: true,
  dúlra: true,
  tiubh: true,
  finné: true,
  botún: true,
  albam: true,
  píopa: true,
  togra: true,
  óráid: true,
  blais: true,
  seach: true,
  neach: true,
  faill: true,
  milis: true,
  tarbh: true,
  múnla: true,
  méara: true,
  fíric: true,
  buama: true,
  baist: true,
  crios: true,
  leaid: true,
  meath: true,
  aosta: true,
  seilg: true,
  goill: true,
  séimh: true,
  scíth: true,
  puinn: true,
  rialú: true,
  gadaí: true,
  maise: true,
  imirt: true,
  ochtó: true,
  craic: true,
  olann: true,
  súigh: true,
  docht: true,
  ailse: true,
  canna: true,
  faire: true,
  caoch: true,
  síoda: true,
  eadar: true,
  cumha: true,
  caife: true,
  sólás: true,
  sáith: true,
  ráfla: true,
  cuach: true,
  airne: true,
  alcól: true,
  luibh: true,
  tnúth: true,
  cearr: true,
  tacht: true,
  tuile: true,
  beach: true,
  éindí: true,
  anoir: true,
  sciar: true,
  plána: true,
  beola: true,
  creat: true,
  cróga: true,
  mothú: true,
  thusa: true,
  aoibh: true,
  sicín: true,
  bearr: true,
  féith: true,
  ortha: true,
  forás: true,
  masla: true,
  cónra: true,
  trian: true,
  dhein: true,
  ceilt: true,
  tosaí: true,
  staon: true,
  goile: true,
  tuama: true,
  uacht: true,
  peata: true,
  laige: true,
  stoca: true,
  fráma: true,
  scaif: true,
  draoi: true,
  beann: true,
  glóir: true,
  teilg: true,
  péist: true,
  luasc: true,
  cathú: true,
  séala: true,
  racht: true,
  ídigh: true,
  snáth: true,
  uaimh: true,
  casóg: true,
  oilte: true,
  smior: true,
  cúisí: true,
  císte: true,
  ireas: true,
  tarlú: true,
  balbh: true,
  cothú: true,
  púdar: true,
  sonas: true,
  seift: true,
  beoir: true,
  lampa: true,
  meaig: true,
  caifé: true,
  éirim: true,
  strae: true,
  mamaí: true,
  umhal: true,
  mionn: true,
  cíoch: true,
  coisc: true,
  tuata: true,
  téamh: true,
  inmhe: true,
  dreap: true,
  bheir: true,
  uatha: true,
  seilf: true,
  leoga: true,
  nócha: true,
  gonta: true,
  iomrá: true,
  dreas: true,
  déirc: true,
  adamh: true,
  díobh: true,
  gread: true,
  leann: true,
  olcas: true,
  urnaí: true,
  gortú: true,
  cearn: true,
  iolra: true,
  gnúis: true,
  péint: true,
  staic: true,
  buair: true,
  tocht: true,
  fostú: true,
  urrús: true,
  leamh: true,
  dóite: true,
  toise: true,
  daidí: true,
  gealt: true,
  veain: true,
  tonna: true,
  focla: true,
  saint: true,
  grean: true,
  aoire: true,
  néata: true,
  ordóg: true,
  liopa: true,
  finne: true,
  úrnua: true,
  tréad: true,
  céimí: true,
  creim: true,
  spota: true,
  donas: true,
  coirm: true,
  camán: true,
  leomh: true,
  chóir: true,
  idéal: true,
  dínit: true,
  cipín: true,
  saill: true,
  fócas: true,
  cófra: true,
  aspal: true,
  pálás: true,
  clais: true,
  slios: true,
  fónta: true,
  copar: true,
  aicíd: true,
  spíon: true,
  stuif: true,
  giall: true,
  coire: true,
  diaga: true,
  gráin: true,
  báúil: true,
  sodar: true,
  modúl: true,
  deasc: true,
  deoin: true,
  uaine: true,
  buaic: true,
  strus: true,
  éigse: true,
  putóg: true,
  Pilib: true,
  loisc: true,
  líomh: true,
  aneas: true,
  clárú: true,
  seoid: true,
  daofa: true,
  fásra: true,
  druma: true,
  ionga: true,
  bogha: true,
  tráta: true,
  bacán: true,
  tacar: true,
  casúr: true,
  rolla: true,
  clúmh: true,
  Conán: true,
  leasc: true,
  líofa: true,
  gréas: true,
  slánú: true,
  plúch: true,
  dealg: true,
  preas: true,
  cuing: true,
  allta: true,
  corca: true,
  iolar: true,
  arann: true,
  sféar: true,
  fuaid: true,
  dréim: true,
  teibí: true,
  gátar: true,
  prioc: true,
  sprid: true,
  sméar: true,
  gotha: true,
  pluid: true,
  colúr: true,
  dáimh: true,
  síniú: true,
  lionn: true,
  fáidh: true,
  Fínín: true,
  athrá: true,
  frása: true,
  goirt: true,
  méith: true,
  orgán: true,
  solad: true,
  matán: true,
  sméid: true,
  gabha: true,
  bábóg: true,
  cábán: true,
  taisc: true,
  tarna: true,
  fiuch: true,
  folús: true,
  ribín: true,
  súgán: true,
  banbh: true,
  stail: true,
  cneas: true,
  leaca: true,
  thoir: true,
  eagal: true,
  cruas: true,
  scóip: true,
  próca: true,
  craos: true,
  faide: true,
  cadás: true,
  glinn: true,
  plaic: true,
  cailc: true,
  luail: true,
  corda: true,
  nuige: true,
  clúid: true,
  áirid: true,
  giúis: true,
  éiric: true,
  seoda: true,
  cruit: true,
  biúró: true,
  chaon: true,
  smear: true,
  mótar: true,
  fidil: true,
  strac: true,
  plota: true,
  cuóta: true,
  íosta: true,
  clóca: true,
  bligh: true,
  gasra: true,
  lúfar: true,
  sáinn: true,
  suirí: true,
  geábh: true,
  creig: true,
  drong: true,
  cábla: true,
  tiúin: true,
  rubar: true,
  teora: true,
  iarla: true,
  ladar: true,
  géire: true,
  ceadú: true,
  péine: true,
  lagar: true,
  tátal: true,
  feann: true,
  deilf: true,
  macha: true,
  oisre: true,
  banda: true,
  smúit: true,
  bríce: true,
  stéig: true,
  cógas: true,
  bagún: true,
  gaois: true,
  víosa: true,
  seasc: true,
  darna: true,
  scinn: true,
  scriú: true,
  eorna: true,
  gríos: true,
  bolta: true,
  lasta: true,
  áirse: true,
  easna: true,
  riasc: true,
  punta: true,
  iníor: true,
  cráin: true,
  coirt: true,
  cunta: true,
  tacaí: true,
  treis: true,
  scean: true,
  trúpa: true,
  ceirt: true,
  céilí: true,
  fogha: true,
  spoch: true,
  casla: true,
  eagna: true,
  gobán: true,
  ispín: true,
  úrach: true,
  babaí: true,
  seile: true,
  théid: true,
  feart: true,
  uaill: true,
  treas: true,
  iaigh: true,
  fisic: true,
  scall: true,
  ísliú: true,
  cling: true,
  gnaoi: true,
  meirg: true,
  liric: true,
  iompó: true,
  seang: true,
  fríth: true,
  cealg: true,
  ceilp: true,
  galún: true,
  codán: true,
  trosc: true,
  círín: true,
  faomh: true,
  leice: true,
  ochtú: true,
  dólás: true,
  scrín: true,
  sceon: true,
  diail: true,
  pianó: true,
  scéin: true,
  taosc: true,
  flosc: true,
  sobal: true,
  crobh: true,
  mánla: true,
  déine: true,
  próis: true,
  tearc: true,
  easóg: true,
  bailí: true,
  maróg: true,
  goimh: true,
  scolb: true,
  sonrú: true,
  mnaoi: true,
  srann: true,
  eitic: true,
  báith: true,
  tighe: true,
  leite: true,
  searr: true,
  baoth: true,
  calma: true,
  fáilí: true,
  crúca: true,
  curfá: true,
  slinn: true,
  múnlú: true,
  críon: true,
  tarra: true,
  fíoch: true,
  buach: true,
  corna: true,
  barda: true,
  éidir: true,
  snigh: true,
  beoga: true,
  básta: true,
  fuáil: true,
  camas: true,
  speal: true,
  coisí: true,
  sciúr: true,
  tóraí: true,
  díleá: true,
  spalp: true,
  ceant: true,
  fánaí: true,
  saobh: true,
  bráth: true,
  searg: true,
  falla: true,
  drúis: true,
  slíoc: true,
  gráig: true,
  oibir: true,
  falsa: true,
  tréas: true,
  radar: true,
  oidhe: true,
  breab: true,
  matal: true,
  baois: true,
  maraí: true,
  bácús: true,
  eilit: true,
  cnead: true,
  drann: true,
  scuad: true,
  balún: true,
  gaibh: true,
  árach: true,
  bulaí: true,
  sanas: true,
  guaim: true,
  dusta: true,
  cuain: true,
  plimp: true,
  ochón: true,
  stáir: true,
  iompú: true,
  boige: true,
  silín: true,
  doire: true,
  rangú: true,
  léana: true,
  canta: true,
  báiní: true,
  seort: true,
  dalba: true,
  searc: true,
  casán: true,
  toill: true,
  trunc: true,
  cumar: true,
  análú: true,
  scáin: true,
  scine: true,
  cuilt: true,
  muirí: true,
  trúig: true,
  lacht: true,
  cáith: true,
  sotal: true,
  múisc: true,
  curaí: true,
  buirg: true,
  racán: true,
  léimt: true,
  bráca: true,
  stróc: true,
  lábán: true,
  colpa: true,
  ogham: true,
  lonnú: true,
  calóg: true,
  céide: true,
  damnú: true,
  dlaoi: true,
  eipic: true,
  aibíd: true,
  moing: true,
  lorga: true,
  veist: true,
  bulla: true,
  stáca: true,
  indiu: true,
  pailm: true,
  caise: true,
  stiúg: true,
  polla: true,
  sleán: true,
  muine: true,
  fonsa: true,
  pluma: true,
  bólaí: true,
  burla: true,
  teasc: true,
  uasta: true,
  dílse: true,
  draid: true,
  plúid: true,
  smúid: true,
  plúis: true,
  abhac: true,
  ábhal: true,
  abhla: true,
  abhóg: true,
  ábhrú: true,
  abthí: true,
  achas: true,
  achra: true,
  achta: true,
  achtú: true,
  aclaí: true,
  acraí: true,
  adaic: true,
  adhal: true,
  adhna: true,
  adhra: true,
  adóib: true,
  aeóin: true,
  aeraí: true,
  aerga: true,
  aeróg: true,
  Aesóp: true,
  agair: true,
  ágair: true,
  agáit: true,
  agall: true,
  agard: true,
  agraí: true,
  agúid: true,
  agúis: true,
  aibím: true,
  aibiú: true,
  aicim: true,
  aicis: true,
  aicmí: true,
  aicne: true,
  Aidan: true,
  aidhm: true,
  Áidin: true,
  aifid: true,
  Aifir: true,
  aifir: true,
  aifrí: true,
  aigne: true,
  aigní: true,
  ailbe: true,
  ailím: true,
  Ailís: true,
  aille: true,
  áille: true,
  áillí: true,
  ailme: true,
  ailmí: true,
  ailpe: true,
  ailse: true,
  ailsí: true,
  ailte: true,
  áilte: true,
  aimhe: true,
  aimsí: true,
  aindé: true,
  Aindí: true,
  aingí: true,
  ainic: true,
  ainil: true,
  ainís: true,
  Ainle: true,
  ainle: true,
  Áinle: true,
  ainlí: true,
  ainte: true,
  airce: true,
  áirce: true,
  aircí: true,
  airde: true,
  airge: true,
  áirge: true,
  áirgí: true,
  áiria: true,
  airím: true,
  áirím: true,
  airne: true,
  Áirne: true,
  airní: true,
  áirse: true,
  áirsí: true,
  aisce: true,
  aiscí: true,
  aisig: true,
  aisil: true,
  áisín: true,
  áisiú: true,
  aiste: true,
  aisti: true,
  aistí: true,
  áithe: true,
  aitil: true,
  aitim: true,
  áitím: true,
  aitis: true,
  áitiú: true,
  áitrí: true,
  áladh: true,
  Alain: true,
  albam: true,
  Alban: true,
  alcól: true,
  Alfie: true,
  algaí: true,
  Alice: true,
  Alito: true,
  allas: true,
  allta: true,
  almsa: true,
  Alpaí: true,
  alpaí: true,
  alpán: true,
  alpfá: true,
  Alpín: true,
  alptá: true,
  altaí: true,
  altán: true,
  altar: true,
  altfá: true,
  altra: true,
  alúim: true,
  amach: true,
  amaid: true,
  amaím: true,
  amais: true,
  amhas: true,
  amhra: true,
  amlóg: true,
  amódh: true,
  amófá: true,
  ámóga: true,
  ampla: true,
  amplú: true,
  anáid: true,
  anáil: true,
  anais: true,
  anáis: true,
  anála: true,
  anall: true,
  análú: true,
  anama: true,
  anann: true,
  anbhá: true,
  aneas: true,
  anfaí: true,
  angar: true,
  aniar: true,
  aníos: true,
  anóid: true,
  anoir: true,
  anois: true,
  anonn: true,
  anord: true,
  Anraí: true,
  ánraí: true,
  anseo: true,
  ansin: true,
  anuas: true,
  Aodán: true,
  Aodha: true,
  Aogán: true,
  aoibh: true,
  Aoidh: true,
  Aoife: true,
  Aoine: true,
  aoine: true,
  Aoire: true,
  aoire: true,
  aoirí: true,
  Aoise: true,
  aoise: true,
  aolfá: true,
  aolta: true,
  aoltá: true,
  aomfá: true,
  aomtá: true,
  Aonad: true,
  aonad: true,
  aonán: true,
  aonar: true,
  aonrú: true,
  aonta: true,
  aontú: true,
  aoraí: true,
  aorfá: true,
  aorta: true,
  aortá: true,
  aosaí: true,
  aosán: true,
  aosta: true,
  aothú: true,
  Apáis: true,
  ápúil: true,
  ápúla: true,
  árach: true,
  Araib: true,
  araid: true,
  aráin: true,
  árais: true,
  aralt: true,
  arann: true,
  Árann: true,
  árann: true,
  araon: true,
  arcán: true,
  árchú: true,
  ardaí: true,
  ardán: true,
  ardas: true,
  ardóg: true,
  ardrí: true,
  aréir: true,
  argfá: true,
  argón: true,
  argtá: true,
  armaí: true,
  armas: true,
  ármhá: true,
  armúr: true,
  Artúr: true,
  arúil: true,
  arúla: true,
  asail: true,
  áscaí: true,
  Ascar: true,
  ascar: true,
  aslaí: true,
  asmaí: true,
  Aspal: true,
  aspal: true,
  astaí: true,
  astar: true,
  asúdh: true,
  asúfá: true,
  asúim: true,
  asúnn: true,
  atadh: true,
  atáid: true,
  ataim: true,
  atáim: true,
  atall: true,
  atann: true,
  atéim: true,
  atfaí: true,
  atfar: true,
  áthán: true,
  Athar: true,
  athar: true,
  áthas: true,
  athlá: true,
  athrá: true,
  athrí: true,
  athrú: true,
  atite: true,
  atití: true,
  atlas: true,
  atofa: true,
  atóga: true,
  atogh: true,
  atóin: true,
  atóna: true,
  atosú: true,
  atrua: true,
  babaí: true,
  bábán: true,
  bábóg: true,
  babún: true,
  bacaí: true,
  bacán: true,
  bácha: true,
  bacóg: true,
  bácús: true,
  badán: true,
  báfar: true,
  bagún: true,
  báibe: true,
  baice: true,
  baige: true,
  báigh: true,
  baigí: true,
  báigí: true,
  bailc: true,
  Baile: true,
  baile: true,
  bailé: true,
  bailí: true,
  baill: true,
  Bailt: true,
  Bainc: true,
  bainc: true,
  báine: true,
  báiní: true,
  baint: true,
  bairb: true,
  báirc: true,
  baird: true,
  báire: true,
  báirí: true,
  bairr: true,
  baisc: true,
  baist: true,
  báite: true,
  báití: true,
  balbh: true,
  balla: true,
  Balor: true,
  balsa: true,
  balsc: true,
  bálta: true,
  balún: true,
  bamba: true,
  bambú: true,
  banaí: true,
  bánaí: true,
  banbh: true,
  banda: true,
  bandé: true,
  banga: true,
  banna: true,
  bánna: true,
  bannú: true,
  bánóg: true,
  bánta: true,
  bánte: true,
  baoil: true,
  baois: true,
  baoth: true,
  baraí: true,
  barda: true,
  barra: true,
  barún: true,
  basal: true,
  basán: true,
  basár: true,
  basta: true,
  básta: true,
  basún: true,
  bataí: true,
  batar: true,
  báúil: true,
  báúla: true,
  beach: true,
  beadh: true,
  beaga: true,
  beáir: true,
  béala: true,
  bealú: true,
  beann: true,
  beara: true,
  bearr: true,
  beart: true,
  béasa: true,
  Becky: true,
  Behan: true,
  beica: true,
  béice: true,
  béicí: true,
  beidh: true,
  beifí: true,
  béile: true,
  Beilg: true,
  béilí: true,
  beilt: true,
  béime: true,
  beinn: true,
  Beirn: true,
  beirt: true,
  béist: true,
  béite: true,
  beith: true,
  beití: true,
  Belém: true,
  Bella: true,
  Bello: true,
  Benny: true,
  beodh: true,
  beoga: true,
  beoim: true,
  beoir: true,
  beola: true,
  beonn: true,
  bhába: true,
  bhaca: true,
  bhách: true,
  bhacú: true,
  bhádh: true,
  bháfá: true,
  bhaic: true,
  bháid: true,
  bhaig: true,
  bhail: true,
  bháil: true,
  bháim: true,
  bhain: true,
  bháin: true,
  bhair: true,
  Bháis: true,
  bháis: true,
  bhalc: true,
  bhall: true,
  bhána: true,
  Bhanc: true,
  bhanc: true,
  bhang: true,
  bhánn: true,
  bhánú: true,
  bhaog: true,
  bhaoi: true,
  bhaol: true,
  bhara: true,
  bhárc: true,
  bhard: true,
  bharr: true,
  bhasc: true,
  bhású: true,
  bhata: true,
  bheag: true,
  Bhéal: true,
  bhéal: true,
  bhean: true,
  bheár: true,
  Bhéar: true,
  bhéar: true,
  bheas: true,
  bhéas: true,
  bhéic: true,
  Bhéil: true,
  bhéil: true,
  bhéim: true,
  bheir: true,
  Bhéir: true,
  bhéir: true,
  bhfad: true,
  bhfág: true,
  bhfaí: true,
  bhFál: true,
  bhfál: true,
  bhfan: true,
  bhfán: true,
  bhfás: true,
  bhfeá: true,
  bhfeo: true,
  bhfia: true,
  bhfid: true,
  bhfíf: true,
  bhfím: true,
  bhFir: true,
  bhfir: true,
  bhfís: true,
  bhfiú: true,
  bhfód: true,
  bhfol: true,
  bhfón: true,
  bhfua: true,
  bhfud: true,
  bhfúg: true,
  bhián: true,
  bhibe: true,
  bhibí: true,
  Bhidé: true,
  bhídí: true,
  bhige: true,
  bhíge: true,
  bhígí: true,
  bhile: true,
  bhilí: true,
  bhinb: true,
  bhinn: true,
  bhínn: true,
  bhíog: true,
  bhíol: true,
  bhior: true,
  bhípe: true,
  bhirt: true,
  bhísc: true,
  bhíse: true,
  bhisí: true,
  bhísí: true,
  bhith: true,
  bhítí: true,
  bhlag: true,
  bhlár: true,
  bhlas: true,
  bhlás: true,
  Bhleá: true,
  bhlím: true,
  bhlíp: true,
  bhloc: true,
  bhlús: true,
  bhocó: true,
  bhoga: true,
  bhogh: true,
  bhoic: true,
  bhoid: true,
  bhoig: true,
  bhóín: true,
  bhois: true,
  bholb: true,
  bholg: true,
  bholú: true,
  bhóna: true,
  bhonn: true,
  bhorb: true,
  bhord: true,
  bhorr: true,
  bhosa: true,
  bhoth: true,
  bhrac: true,
  bhrád: true,
  bhran: true,
  bhrat: true,
  bhreá: true,
  bhreo: true,
  bhric: true,
  bhrín: true,
  bhris: true,
  bhroc: true,
  bhród: true,
  bhróg: true,
  bhrón: true,
  bhrus: true,
  bhuac: true,
  bhuaf: true,
  bhuan: true,
  bhuar: true,
  bhúch: true,
  Bhúda: true,
  bhuel: true,
  bhufa: true,
  bhuím: true,
  bhuin: true,
  bhúir: true,
  bhúis: true,
  bhúit: true,
  bhulc: true,
  bhunc: true,
  bhunú: true,
  bhuta: true,
  biáid: true,
  biail: true,
  biáin: true,
  biata: true,
  bídís: true,
  bigil: true,
  Bille: true,
  bille: true,
  billí: true,
  bímid: true,
  bímis: true,
  bímse: true,
  binbe: true,
  binbí: true,
  binid: true,
  binne: true,
  binse: true,
  binsí: true,
  bíodh: true,
  bíoga: true,
  bíoma: true,
  bíonn: true,
  biora: true,
  biorú: true,
  Birch: true,
  birín: true,
  bísce: true,
  bisím: true,
  bisiú: true,
  Bitín: true,
  bitsí: true,
  biúró: true,
  Bláca: true,
  Blair: true,
  bláir: true,
  blais: true,
  bláis: true,
  Blake: true,
  bláth: true,
  bleán: true,
  bleib: true,
  bleid: true,
  bléin: true,
  blide: true,
  bligh: true,
  blígí: true,
  blióg: true,
  blípe: true,
  blite: true,
  bloba: true,
  blogh: true,
  bloic: true,
  blosc: true,
  blúis: true,
  bobán: true,
  bocaí: true,
  bocht: true,
  bodán: true,
  bodóg: true,
  bógaí: true,
  bogán: true,
  bogás: true,
  bogha: true,
  bogóg: true,
  boige: true,
  boilb: true,
  boilg: true,
  bóíní: true,
  boinn: true,
  bóinn: true,
  boirb: true,
  boird: true,
  bóirí: true,
  boise: true,
  bólaí: true,
  bolla: true,
  bológ: true,
  bolta: true,
  bómán: true,
  bónaí: true,
  bónas: true,
  bongó: true,
  bónna: true,
  bonnú: true,
  bóraí: true,
  bórás: true,
  borba: true,
  borbú: true,
  Boris: true,
  bórón: true,
  borra: true,
  bosaí: true,
  bosán: true,
  bósan: true,
  bosca: true,
  bósón: true,
  botaí: true,
  botha: true,
  botún: true,
  braic: true,
  bráid: true,
  braig: true,
  brait: true,
  braoi: true,
  braon: true,
  brath: true,
  bráth: true,
  bratú: true,
  breab: true,
  breac: true,
  bréad: true,
  bréag: true,
  bréan: true,
  bréid: true,
  bréin: true,
  breis: true,
  Brian: true,
  brice: true,
  bríce: true,
  brící: true,
  Bríde: true,
  brídí: true,
  briog: true,
  brisc: true,
  brise: true,
  brobh: true,
  bróga: true,
  broic: true,
  broid: true,
  bróid: true,
  bróig: true,
  broim: true,
  Broin: true,
  bróin: true,
  bróis: true,
  broit: true,
  broma: true,
  Bróna: true,
  bronn: true,
  Brown: true,
  bruán: true,
  bruar: true,
  bruas: true,
  brúdh: true,
  brugh: true,
  brúid: true,
  bruig: true,
  brúim: true,
  bruis: true,
  brúnn: true,
  brúta: true,
  bruth: true,
  buach: true,
  buadh: true,
  buafa: true,
  buaic: true,
  buail: true,
  buaim: true,
  buain: true,
  buair: true,
  buama: true,
  buana: true,
  buann: true,
  buanú: true,
  búcha: true,
  búcla: true,
  bugaí: true,
  buíán: true,
  búidh: true,
  buifé: true,
  buígh: true,
  buígí: true,
  builc: true,
  buile: true,
  buime: true,
  buimí: true,
  buinc: true,
  buíne: true,
  buíóg: true,
  buíon: true,
  búire: true,
  buirg: true,
  Búirí: true,
  búirí: true,
  buíte: true,
  bulaí: true,
  bulba: true,
  bulla: true,
  bunán: true,
  búnna: true,
  bunóc: true,
  bunrí: true,
  bunús: true,
  burla: true,
  burra: true,
  bursa: true,
  busta: true,
  butaí: true,
  bútán: true,
  cabaí: true,
  cábaí: true,
  cábán: true,
  cabar: true,
  cabha: true,
  cábla: true,
  cáblú: true,
  cábóg: true,
  cábún: true,
  cabús: true,
  cácaí: true,
  Cacas: true,
  cácas: true,
  cadán: true,
  cadás: true,
  cadhc: true,
  cadóg: true,
  cagúl: true,
  cáich: true,
  caicí: true,
  caide: true,
  caife: true,
  caifí: true,
  caígh: true,
  caígí: true,
  cailc: true,
  caile: true,
  cáile: true,
  cailg: true,
  Cailí: true,
  cailí: true,
  caill: true,
  cailm: true,
  caime: true,
  cáime: true,
  cáimí: true,
  cainc: true,
  Caine: true,
  caint: true,
  cairb: true,
  cairn: true,
  cairr: true,
  cairt: true,
  cáirt: true,
  cáisc: true,
  caise: true,
  cáise: true,
  caisí: true,
  caite: true,
  caíte: true,
  cáite: true,
  caith: true,
  cáith: true,
  caití: true,
  cáití: true,
  calaí: true,
  calán: true,
  calar: true,
  calbh: true,
  calla: true,
  calma: true,
  calmú: true,
  calóg: true,
  calra: true,
  camán: true,
  camas: true,
  cámas: true,
  camóg: true,
  campa: true,
  camra: true,
  canad: true,
  cánaí: true,
  canna: true,
  cannú: true,
  cánóg: true,
  canta: true,
  caoch: true,
  caoga: true,
  caoil: true,
  caoin: true,
  Caola: true,
  caola: true,
  caolú: true,
  caomh: true,
  caora: true,
  capán: true,
  carad: true,
  caraf: true,
  cáraí: true,
  carán: true,
  carat: true,
  cárbh: true,
  caróg: true,
  carón: true,
  carra: true,
  carta: true,
  cárta: true,
  carúl: true,
  casaí: true,
  cásaí: true,
  casal: true,
  cásca: true,
  casla: true,
  casóg: true,
  casta: true,
  casúr: true,
  cataí: true,
  catha: true,
  cátha: true,
  cathú: true,
  catóg: true,
  ceada: true,
  ceadú: true,
  céadú: true,
  ceaig: true,
  cealg: true,
  ceall: true,
  cealú: true,
  ceama: true,
  ceana: true,
  ceann: true,
  ceant: true,
  ceapa: true,
  cearc: true,
  ceard: true,
  céard: true,
  cearn: true,
  cearr: true,
  ceart: true,
  ceasa: true,
  céasa: true,
  ceast: true,
  céide: true,
  céidí: true,
  céile: true,
  céilí: true,
  céill: true,
  ceilp: true,
  ceilt: true,
  céime: true,
  céimí: true,
  céine: true,
  ceint: true,
  ceird: true,
  céire: true,
  ceirt: true,
  ceise: true,
  céise: true,
  ceist: true,
  ceoch: true,
  ceoil: true,
  cérbh: true,
  chába: true,
  chaca: true,
  cháca: true,
  chách: true,
  chadó: true,
  chága: true,
  chaib: true,
  chaid: true,
  cháig: true,
  cháil: true,
  chaim: true,
  chaím: true,
  cháim: true,
  cháin: true,
  cháir: true,
  chais: true,
  cháis: true,
  chait: true,
  chalc: true,
  chall: true,
  chalm: true,
  chalú: true,
  chama: true,
  chana: true,
  chána: true,
  chanú: true,
  chaoi: true,
  chaol: true,
  chaor: true,
  chapa: true,
  chara: true,
  charn: true,
  charr: true,
  chart: true,
  chárt: true,
  chasa: true,
  chath: true,
  chatú: true,
  chead: true,
  chéad: true,
  cheal: true,
  cheam: true,
  cheap: true,
  cheas: true,
  chéas: true,
  chéid: true,
  cheil: true,
  chéim: true,
  chéin: true,
  chéir: true,
  Cheis: true,
  cheis: true,
  chéis: true,
  cheol: true,
  chian: true,
  chiap: true,
  chiar: true,
  chíbe: true,
  chíbí: true,
  chíle: true,
  chílí: true,
  chime: true,
  chimí: true,
  chinn: true,
  chion: true,
  chíor: true,
  chíos: true,
  chiot: true,
  chipe: true,
  chipí: true,
  chíre: true,
  chirt: true,
  chise: true,
  chisí: true,
  chist: true,
  chith: true,
  chiúb: true,
  chlab: true,
  chlag: true,
  chlaí: true,
  chlár: true,
  chlás: true,
  chlib: true,
  chlic: true,
  chlip: true,
  chlis: true,
  chlog: true,
  chloí: true,
  chlón: true,
  chlos: true,
  chlós: true,
  chlub: true,
  chnag: true,
  chnaí: true,
  chnap: true,
  chnat: true,
  chneá: true,
  chnis: true,
  chnoc: true,
  choca: true,
  chóch: true,
  chócó: true,
  choda: true,
  chódú: true,
  choic: true,
  chóic: true,
  chóid: true,
  choil: true,
  choim: true,
  chóin: true,
  choip: true,
  chóip: true,
  choir: true,
  chóir: true,
  chois: true,
  chola: true,
  chóla: true,
  cholg: true,
  choll: true,
  cholm: true,
  chóma: true,
  chomh: true,
  chonn: true,
  chora: true,
  chóra: true,
  chorb: true,
  chorc: true,
  chorn: true,
  chorp: true,
  chorr: true,
  chosa: true,
  chosc: true,
  chóta: true,
  chrág: true,
  chrap: true,
  chríl: true,
  chrín: true,
  chriú: true,
  chroí: true,
  chrom: true,
  chróm: true,
  chrón: true,
  Chros: true,
  chros: true,
  chrua: true,
  chrúb: true,
  chual: true,
  Chuan: true,
  chuan: true,
  chuán: true,
  chuar: true,
  chuas: true,
  Chúba: true,
  chúba: true,
  Chuck: true,
  chucu: true,
  chufa: true,
  chuid: true,
  chuig: true,
  chúig: true,
  chuil: true,
  chúil: true,
  chuir: true,
  chúir: true,
  chúis: true,
  chúla: true,
  chúlú: true,
  chuma: true,
  chúng: true,
  chupa: true,
  chuta: true,
  chúta: true,
  ciabh: true,
  ciach: true,
  ciall: true,
  ciana: true,
  Ciara: true,
  ciara: true,
  cíche: true,
  cifle: true,
  ciflí: true,
  cigil: true,
  cille: true,
  cillí: true,
  cimím: true,
  cimiú: true,
  Cindy: true,
  cíoch: true,
  cíoma: true,
  ciona: true,
  cíona: true,
  cíoná: true,
  cionn: true,
  cíora: true,
  cíosa: true,
  cíosú: true,
  ciota: true,
  cipín: true,
  Cipir: true,
  circe: true,
  círín: true,
  cirte: true,
  Cisco: true,
  cisil: true,
  cisim: true,
  Ciste: true,
  ciste: true,
  císte: true,
  cistí: true,
  cístí: true,
  citil: true,
  citrí: true,
  ciúbú: true,
  ciúib: true,
  ciúin: true,
  ciúnú: true,
  ciúta: true,
  claba: true,
  Claes: true,
  claib: true,
  claig: true,
  Clair: true,
  Cláir: true,
  cláir: true,
  clais: true,
  cláis: true,
  clamh: true,
  clann: true,
  claon: true,
  Clara: true,
  Clare: true,
  Clark: true,
  clárú: true,
  clasú: true,
  cleas: true,
  Clegg: true,
  cléir: true,
  cléit: true,
  Clery: true,
  cliar: true,
  clibe: true,
  clíce: true,
  clící: true,
  clígh: true,
  cling: true,
  Clint: true,
  clipe: true,
  clipí: true,
  clísé: true,
  clisí: true,
  clíth: true,
  clóbh: true,
  clóca: true,
  cloch: true,
  clódh: true,
  clóic: true,
  cloig: true,
  cloím: true,
  clóim: true,
  clóin: true,
  clois: true,
  clóis: true,
  clónn: true,
  clord: true,
  cluas: true,
  clúid: true,
  cluin: true,
  clúmh: true,
  cnádú: true,
  cnaga: true,
  cnáib: true,
  cnáid: true,
  cnaig: true,
  cnaím: true,
  cnaip: true,
  cnait: true,
  cnámh: true,
  cnead: true,
  cneas: true,
  cniog: true,
  cnoba: true,
  cnoga: true,
  Cnoic: true,
  cnoic: true,
  cnota: true,
  cnuas: true,
  cóála: true,
  cobra: true,
  cocaí: true,
  cocán: true,
  cocas: true,
  cócha: true,
  cocól: true,
  cocún: true,
  cocús: true,
  codaí: true,
  codam: true,
  codán: true,
  Cófra: true,
  cófra: true,
  Cogaí: true,
  cogaí: true,
  cogal: true,
  Cogan: true,
  cogar: true,
  cógas: true,
  cogús: true,
  Cohen: true,
  cóich: true,
  coilg: true,
  coilí: true,
  coill: true,
  coilm: true,
  coime: true,
  Coinn: true,
  coinn: true,
  cóipe: true,
  coipí: true,
  coirb: true,
  coirc: true,
  coire: true,
  Cóiré: true,
  coirí: true,
  coirm: true,
  Coirn: true,
  coirn: true,
  coirp: true,
  coirt: true,
  coisc: true,
  coise: true,
  coisí: true,
  Cóísí: true,
  coite: true,
  coití: true,
  colaí: true,
  Colam: true,
  Colin: true,
  Colla: true,
  cológ: true,
  colpa: true,
  colta: true,
  Colum: true,
  colún: true,
  colúr: true,
  cómaí: true,
  Comar: true,
  comha: true,
  común: true,
  cónaí: true,
  Conan: true,
  conán: true,
  conas: true,
  conda: true,
  Condi: true,
  Conga: true,
  Congó: true,
  conlú: true,
  cónra: true,
  conrú: true,
  Conry: true,
  Cooke: true,
  copar: true,
  copóg: true,
  coraí: true,
  córaí: true,
  córam: true,
  Córan: true,
  córas: true,
  Corca: true,
  corda: true,
  Corey: true,
  Corfú: true,
  corna: true,
  corra: true,
  Corry: true,
  cosán: true,
  Costa: true,
  Cósta: true,
  cósta: true,
  cótaí: true,
  cothú: true,
  Cotón: true,
  cotún: true,
  Cowen: true,
  Coyle: true,
  Coyne: true,
  crádh: true,
  crága: true,
  craic: true,
  Craig: true,
  cráim: true,
  cráin: true,
  Crane: true,
  crann: true,
  cránn: true,
  craol: true,
  craos: true,
  cráta: true,
  créad: true,
  Creag: true,
  créam: true,
  Crean: true,
  creat: true,
  creid: true,
  creig: true,
  creim: true,
  créip: true,
  Créit: true,
  crián: true,
  crích: true,
  Crimé: true,
  críne: true,
  crinn: true,
  criól: true,
  críol: true,
  críon: true,
  Crios: true,
  crios: true,
  crite: true,
  crith: true,
  crobh: true,
  croca: true,
  croch: true,
  cróch: true,
  crodh: true,
  cróga: true,
  croim: true,
  cróim: true,
  cróin: true,
  Crois: true,
  croit: true,
  Cróit: true,
  Croke: true,
  croma: true,
  cróna: true,
  cronú: true,
  crónú: true,
  crosa: true,
  Cross: true,
  Crowe: true,
  cruaí: true,
  cruan: true,
  cruas: true,
  crúba: true,
  crúca: true,
  crúdh: true,
  cruib: true,
  crúim: true,
  cruit: true,
  Crump: true,
  crúnn: true,
  cruóg: true,
  cruth: true,
  cuach: true,
  Cuaig: true,
  cuail: true,
  Cuain: true,
  cuain: true,
  Cuáin: true,
  cuáin: true,
  cuair: true,
  cuais: true,
  Cuáit: true,
  Cuana: true,
  cuara: true,
  cuarc: true,
  cuasa: true,
  cúbóg: true,
  cudal: true,
  cufaí: true,
  Cuffe: true,
  cúgar: true,
  cúibe: true,
  cuidí: true,
  cuifí: true,
  Cúige: true,
  cúige: true,
  cúigí: true,
  Cúile: true,
  cuilí: true,
  Cuill: true,
  cuilt: true,
  Cuing: true,
  cuing: true,
  Cuinn: true,
  Cuirc: true,
  cuirí: true,
  Cúirt: true,
  cúirt: true,
  cúise: true,
  cúisí: true,
  cúití: true,
  cúlaí: true,
  cúlán: true,
  cúlóg: true,
  cúlóm: true,
  cúlra: true,
  Cumae: true,
  cumaí: true,
  cumar: true,
  cumas: true,
  cumha: true,
  cumrú: true,
  cúnga: true,
  cúngú: true,
  cúnna: true,
  cunta: true,
  cúnta: true,
  cunús: true,
  cuóta: true,
  cupaí: true,
  cupán: true,
  Cúpla: true,
  cúpla: true,
  cúpón: true,
  curaí: true,
  cúraí: true,
  cúram: true,
  cúrán: true,
  curca: true,
  curfá: true,
  Curly: true,
  cúróg: true,
  curra: true,
  Curry: true,
  cúrsa: true,
  cuspa: true,
  cutaí: true,
  dabaí: true,
  dabht: true,
  Dacár: true,
  Daeid: true,
  Daidí: true,
  daidí: true,
  daigh: true,
  dáigh: true,
  dailc: true,
  dáilí: true,
  daill: true,
  daimh: true,
  dáimh: true,
  dairt: true,
  daise: true,
  daite: true,
  daith: true,
  dálaí: true,
  dalba: true,
  dalla: true,
  dalta: true,
  damba: true,
  dámha: true,
  damnú: true,
  Danar: true,
  danar: true,
  dánta: true,
  daoil: true,
  daoir: true,
  daora: true,
  darbh: true,
  dátaí: true,
  datha: true,
  Dathó: true,
  dathú: true,
  déach: true,
  déada: true,
  deafa: true,
  déaga: true,
  Deáin: true,
  dealg: true,
  dealú: true,
  deann: true,
  deara: true,
  dearc: true,
  dearg: true,
  deasa: true,
  deasc: true,
  deasú: true,
  déata: true,
  deatú: true,
  deice: true,
  deich: true,
  déich: true,
  déigh: true,
  deile: true,
  déile: true,
  deilf: true,
  Deilí: true,
  deilí: true,
  deilt: true,
  déine: true,
  déirc: true,
  deire: true,
  Deirg: true,
  deirg: true,
  Deirí: true,
  deirí: true,
  déirí: true,
  deise: true,
  déise: true,
  Déisí: true,
  deoch: true,
  deoin: true,
  deoir: true,
  deona: true,
  deonú: true,
  deora: true,
  dhaba: true,
  dhada: true,
  dháfa: true,
  dhaid: true,
  Dháil: true,
  dháil: true,
  dháin: true,
  dhair: true,
  dháir: true,
  dhais: true,
  Dhaka: true,
  Dhála: true,
  dhála: true,
  dhall: true,
  dháma: true,
  dhamh: true,
  dhámh: true,
  dhána: true,
  dhaoi: true,
  dhaol: true,
  dhaor: true,
  Dhara: true,
  dhara: true,
  dháta: true,
  dhath: true,
  dhátú: true,
  dhéad: true,
  dhéag: true,
  dhéan: true,
  dhear: true,
  dheas: true,
  dheic: true,
  dhéid: true,
  dheil: true,
  dhéil: true,
  dhein: true,
  dhéin: true,
  dheir: true,
  dheis: true,
  dheor: true,
  dhera: true,
  dhian: true,
  dhias: true,
  dhiat: true,
  dhide: true,
  dhidí: true,
  dhíge: true,
  dhile: true,
  dhíle: true,
  dhílí: true,
  dhing: true,
  dhioc: true,
  dhíog: true,
  dhíol: true,
  dhíon: true,
  dhipe: true,
  dhírí: true,
  dhísc: true,
  dhíse: true,
  dhíth: true,
  dhiúc: true,
  dhiúg: true,
  dhiúl: true,
  dhlím: true,
  dhlús: true,
  dhódh: true,
  dhódó: true,
  dhófá: true,
  dhóib: true,
  dhoic: true,
  dhoid: true,
  dhóid: true,
  dhóil: true,
  dhóim: true,
  dhóip: true,
  dhoir: true,
  dhois: true,
  dhola: true,
  dhona: true,
  dhonn: true,
  dhónn: true,
  dhorc: true,
  dhord: true,
  dhorn: true,
  dhorr: true,
  dhorú: true,
  dhrae: true,
  dhrár: true,
  dhras: true,
  dhreo: true,
  dhris: true,
  dhrol: true,
  dhron: true,
  dhrúp: true,
  dhual: true,
  dhuan: true,
  dhuán: true,
  dhubh: true,
  dhúch: true,
  dhuga: true,
  dhúid: true,
  dhúil: true,
  Dhúin: true,
  dhúin: true,
  dhúir: true,
  dhula: true,
  dhúlú: true,
  dhúna: true,
  dhúra: true,
  diach: true,
  diaga: true,
  diagú: true,
  diail: true,
  diair: true,
  diais: true,
  diall: true,
  Diana: true,
  diana: true,
  Diána: true,
  dianú: true,
  diasa: true,
  díbhe: true,
  díbir: true,
  díblí: true,
  dícir: true,
  difir: true,
  digit: true,
  díleá: true,
  dílis: true,
  dílse: true,
  dílsí: true,
  dímrí: true,
  dinge: true,
  dínit: true,
  dínne: true,
  díobh: true,
  dioca: true,
  dioch: true,
  díofa: true,
  díoga: true,
  díola: true,
  díomá: true,
  diomú: true,
  diosc: true,
  díosc: true,
  dioth: true,
  díoth: true,
  díreo: true,
  dírím: true,
  díriú: true,
  dísce: true,
  dísle: true,
  díslí: true,
  díthe: true,
  diúch: true,
  diucs: true,
  diúga: true,
  diúic: true,
  diúid: true,
  diúil: true,
  diúra: true,
  Dixon: true,
  dlaoi: true,
  dligh: true,
  dlígí: true,
  dlite: true,
  dlúis: true,
  dlúth: true,
  dócha: true,
  docht: true,
  dócúl: true,
  dodaí: true,
  Dodds: true,
  dófar: true,
  dogma: true,
  dóibe: true,
  dóibh: true,
  doice: true,
  dóide: true,
  dóigh: true,
  dóigí: true,
  doilí: true,
  doinn: true,
  doirb: true,
  doirc: true,
  doird: true,
  Doire: true,
  doire: true,
  dóire: true,
  doirí: true,
  dóirí: true,
  doirn: true,
  doirt: true,
  dóite: true,
  dóití: true,
  dolaí: true,
  Dolan: true,
  dólás: true,
  Dolly: true,
  dolta: true,
  doluí: true,
  domsa: true,
  Donal: true,
  Dónal: true,
  donán: true,
  donas: true,
  Donie: true,
  Donna: true,
  donna: true,
  dónna: true,
  donnú: true,
  Doody: true,
  dorád: true,
  Dóraí: true,
  dóraí: true,
  Doran: true,
  doras: true,
  Doris: true,
  dosaí: true,
  dosán: true,
  dósan: true,
  Doyle: true,
  draid: true,
  dráir: true,
  drais: true,
  dráma: true,
  drámh: true,
  drann: true,
  draoi: true,
  dream: true,
  dreap: true,
  dreas: true,
  dréim: true,
  drige: true,
  drill: true,
  driog: true,
  drise: true,
  drisí: true,
  droim: true,
  droin: true,
  drola: true,
  droma: true,
  drona: true,
  drong: true,
  dronn: true,
  druga: true,
  druid: true,
  druil: true,
  drúip: true,
  drúis: true,
  druma: true,
  drúth: true,
  Duach: true,
  duail: true,
  duain: true,
  duáin: true,
  Dúain: true,
  duais: true,
  dubha: true,
  dúbla: true,
  ducht: true,
  dúdaí: true,
  dúdóg: true,
  dufal: true,
  dugaí: true,
  duibh: true,
  dúide: true,
  dúigh: true,
  dúile: true,
  dúilí: true,
  Dúill: true,
  duine: true,
  Duinn: true,
  dúinn: true,
  duirc: true,
  dúire: true,
  Duirn: true,
  dúirt: true,
  Dukes: true,
  dúlaí: true,
  dúlia: true,
  dúlra: true,
  dulta: true,
  dúluí: true,
  dúmas: true,
  dumha: true,
  dumpa: true,
  dúnán: true,
  Dunne: true,
  dunsa: true,
  dúnta: true,
  Dúras: true,
  dúreo: true,
  dúrua: true,
  dúrud: true,
  dúrún: true,
  dusma: true,
  dusta: true,
  Dwane: true,
  Dwyer: true,
  Dylan: true,
  Dyson: true,
  Éabha: true,
  Eabró: true,
  eacha: true,
  éacht: true,
  éadaí: true,
  éadan: true,
  éadar: true,
  eadra: true,
  éadrá: true,
  éafód: true,
  eagal: true,
  eagán: true,
  eagar: true,
  éagfá: true,
  eagla: true,
  eaglú: true,
  eagna: true,
  eagrú: true,
  éagtá: true,
  ealaí: true,
  éalaí: true,
  Éalár: true,
  ealta: true,
  ealtú: true,
  éamar: true,
  Eames: true,
  éamha: true,
  Eamon: true,
  Éamon: true,
  éanán: true,
  eanga: true,
  eangú: true,
  Eanna: true,
  Éanna: true,
  earba: true,
  earca: true,
  earcú: true,
  éarfá: true,
  Earós: true,
  earra: true,
  éartá: true,
  easaí: true,
  éasca: true,
  éascú: true,
  easna: true,
  easóg: true,
  easpa: true,
  easrú: true,
  eatal: true,
  éatar: true,
  eatha: true,
  Eddie: true,
  éidím: true,
  Éidin: true,
  éidís: true,
  éidiú: true,
  éifeá: true,
  éifid: true,
  éigin: true,
  éigis: true,
  éigní: true,
  éigse: true,
  éigsí: true,
  Eilbe: true,
  eilce: true,
  éilím: true,
  Eilín: true,
  Eilís: true,
  Éilis: true,
  Éilís: true,
  eilit: true,
  éille: true,
  éillí: true,
  éimhí: true,
  éimid: true,
  éimím: true,
  Éimin: true,
  éimir: true,
  éimír: true,
  éimis: true,
  éimiú: true,
  éindí: true,
  Éinrí: true,
  éinne: true,
  eipic: true,
  éiric: true,
  éirim: true,
  éirím: true,
  Eirit: true,
  Éirne: true,
  éirní: true,
  eirre: true,
  eisce: true,
  eisia: true,
  eisil: true,
  éisil: true,
  eisím: true,
  éiste: true,
  éistí: true,
  éiteá: true,
  eitic: true,
  eitil: true,
  eitím: true,
  éitir: true,
  éitis: true,
  eitlí: true,
  eitní: true,
  eitre: true,
  eitrí: true,
  éitrí: true,
  Elena: true,
  Eliot: true,
  Ellen: true,
  Ellis: true,
  Eolaí: true,
  eolaí: true,
  eolas: true,
  eorna: true,
  Eorpa: true,
  fabht: true,
  facie: true,
  facto: true,
  fadar: true,
  fadhb: true,
  fadóg: true,
  faide: true,
  fáidh: true,
  faigh: true,
  failc: true,
  faile: true,
  fáilí: true,
  faill: true,
  failm: true,
  failp: true,
  Faing: true,
  fainn: true,
  faire: true,
  fáire: true,
  fairí: true,
  faírí: true,
  fáisc: true,
  fáise: true,
  faití: true,
  falsa: true,
  falsú: true,
  falta: true,
  fálta: true,
  fánaí: true,
  fánán: true,
  fánas: true,
  fanna: true,
  fannú: true,
  Fanny: true,
  fanta: true,
  faofa: true,
  faoil: true,
  faoin: true,
  faomh: true,
  faona: true,
  farad: true,
  farae: true,
  faraí: true,
  faram: true,
  farat: true,
  fásra: true,
  fásta: true,
  fátha: true,
  Feabh: true,
  feaca: true,
  féach: true,
  feadh: true,
  feádh: true,
  feaga: true,
  feáim: true,
  feall: true,
  feama: true,
  feann: true,
  feánn: true,
  feara: true,
  féara: true,
  fearb: true,
  fearg: true,
  fearn: true,
  fearr: true,
  feart: true,
  feasa: true,
  féata: true,
  féich: true,
  feide: true,
  feifí: true,
  féige: true,
  féigh: true,
  féigí: true,
  Féile: true,
  féile: true,
  féilí: true,
  feilt: true,
  feirc: true,
  feire: true,
  féire: true,
  feirg: true,
  feirí: true,
  feirm: true,
  feise: true,
  feisí: true,
  féith: true,
  Felim: true,
  Felix: true,
  feodh: true,
  feoid: true,
  feoil: true,
  feoim: true,
  feola: true,
  feonn: true,
  fhaca: true,
  fhacs: true,
  fhada: true,
  fhadú: true,
  fhága: true,
  fhaic: true,
  fhaid: true,
  fhail: true,
  Fháil: true,
  fháil: true,
  fháin: true,
  fhair: true,
  fháir: true,
  fháis: true,
  fhala: true,
  fhála: true,
  fhálú: true,
  fhana: true,
  fhána: true,
  fhann: true,
  fhánú: true,
  fhaol: true,
  fhaon: true,
  fhara: true,
  fharó: true,
  fhása: true,
  fhású: true,
  fháth: true,
  fheac: true,
  fhead: true,
  fhéad: true,
  fheag: true,
  fheam: true,
  fhean: true,
  Fhear: true,
  fhear: true,
  fhéar: true,
  fheic: true,
  fheil: true,
  fhéil: true,
  fhéir: true,
  fheis: true,
  fheod: true,
  fhial: true,
  fhián: true,
  fhiar: true,
  fhích: true,
  fhífe: true,
  fhífí: true,
  fhige: true,
  fhigh: true,
  fhigí: true,
  fhígí: true,
  fhile: true,
  fhilí: true,
  fhill: true,
  Fhine: true,
  fhine: true,
  fhíní: true,
  fhinn: true,
  fhínn: true,
  fhíon: true,
  Fhíor: true,
  fhíor: true,
  fhios: true,
  fhíre: true,
  fhíse: true,
  fhísí: true,
  fhite: true,
  fhití: true,
  fhiús: true,
  fhlap: true,
  fhlas: true,
  fhleá: true,
  fhlíp: true,
  fhlít: true,
  fhliú: true,
  fhlór: true,
  fhlós: true,
  fhódú: true,
  fhóid: true,
  fhóin: true,
  fhóir: true,
  fhola: true,
  fholc: true,
  fholt: true,
  fholú: true,
  fhóna: true,
  fhonn: true,
  fhorc: true,
  fhosú: true,
  fhras: true,
  fhríd: true,
  fhroc: true,
  fhrog: true,
  fhual: true,
  fhuar: true,
  Fhuig: true,
  fhuig: true,
  fhuil: true,
  fhuin: true,
  fhuip: true,
  fhunc: true,
  fhurú: true,
  fhusa: true,
  fhuta: true,
  fiach: true,
  fiáin: true,
  fiair: true,
  fiala: true,
  fiamh: true,
  Fiann: true,
  fiann: true,
  fianú: true,
  fiara: true,
  fiata: true,
  fíbín: true,
  fiche: true,
  Fidel: true,
  fidil: true,
  fídís: true,
  fidle: true,
  Fidsí: true,
  figín: true,
  fílim: true,
  fímid: true,
  fímis: true,
  Finan: true,
  finid: true,
  fíním: true,
  Finín: true,
  Fínín: true,
  fíniú: true,
  finne: true,
  finné: true,
  finte: true,
  fíoch: true,
  Fiodh: true,
  fiodh: true,
  fíodh: true,
  Fiona: true,
  fíona: true,
  fionn: true,
  fíonn: true,
  fíora: true,
  fiord: true,
  fíorú: true,
  fiosú: true,
  fíric: true,
  firín: true,
  fisic: true,
  fiuch: true,
  fiúir: true,
  fiúis: true,
  Flann: true,
  flann: true,
  flapa: true,
  flasa: true,
  flípe: true,
  flóis: true,
  flong: true,
  Flood: true,
  flóra: true,
  flosc: true,
  flóta: true,
  Floyd: true,
  Flynn: true,
  focal: true,
  fócas: true,
  fódaí: true,
  fódán: true,
  fodar: true,
  Fódla: true,
  fogas: true,
  fogha: true,
  fógra: true,
  fóibe: true,
  fóibí: true,
  fóill: true,
  foilt: true,
  foinn: true,
  fóint: true,
  foirb: true,
  foirc: true,
  foirm: true,
  fóisc: true,
  fóisí: true,
  Folan: true,
  Foley: true,
  folig: true,
  folta: true,
  folús: true,
  fonsa: true,
  fonsú: true,
  fónta: true,
  foral: true,
  fóram: true,
  forar: true,
  foras: true,
  forás: true,
  forba: true,
  Forde: true,
  Forey: true,
  forma: true,
  fórsa: true,
  fosta: true,
  fostú: true,
  fotha: true,
  fothú: true,
  fótón: true,
  Fraic: true,
  frais: true,
  fráma: true,
  frámú: true,
  franc: true,
  Frank: true,
  frapa: true,
  frasa: true,
  frása: true,
  frasú: true,
  Freda: true,
  Freud: true,
  fríde: true,
  frídí: true,
  Fried: true,
  Friel: true,
  fríos: true,
  frisc: true,
  Frist: true,
  fríth: true,
  froga: true,
  froig: true,
  frois: true,
  fronn: true,
  fuadh: true,
  fuail: true,
  fuáil: true,
  fuaim: true,
  fuair: true,
  fuála: true,
  fuann: true,
  fuara: true,
  fuarú: true,
  fuath: true,
  fudar: true,
  fúibh: true,
  fuile: true,
  fuíll: true,
  fúinn: true,
  fuipe: true,
  Fúire: true,
  fuisc: true,
  fuist: true,
  fúmsa: true,
  furca: true,
  fústa: true,
  fútar: true,
  fúthu: true,
  fútsa: true,
  Gabay: true,
  gabha: true,
  gábha: true,
  gadaí: true,
  Gaeil: true,
  Gaelú: true,
  gágaí: true,
  Gáias: true,
  gáibh: true,
  gáifí: true,
  gaige: true,
  gáige: true,
  gaigí: true,
  gaile: true,
  gailf: true,
  Gaill: true,
  gaing: true,
  gaíon: true,
  gaire: true,
  gáire: true,
  gairg: true,
  gáirí: true,
  gairm: true,
  gairr: true,
  galaí: true,
  gálaí: true,
  galán: true,
  galar: true,
  galrú: true,
  galún: true,
  gámaí: true,
  gamal: true,
  gamba: true,
  Gánaí: true,
  ganga: true,
  ganna: true,
  gaoil: true,
  gaois: true,
  Gaora: true,
  gaota: true,
  Gaoth: true,
  gaoth: true,
  garaí: true,
  garbh: true,
  Garda: true,
  garda: true,
  garga: true,
  garma: true,
  Garry: true,
  Garth: true,
  gásaí: true,
  gasán: true,
  gasóg: true,
  gasra: true,
  gasta: true,
  gasúr: true,
  gátar: true,
  Gates: true,
  gathú: true,
  geábh: true,
  geaca: true,
  geafa: true,
  géaga: true,
  géagú: true,
  geala: true,
  geall: true,
  gealt: true,
  geama: true,
  geana: true,
  geanc: true,
  géara: true,
  géará: true,
  gearb: true,
  geard: true,
  gearg: true,
  gearr: true,
  géarú: true,
  geasa: true,
  geata: true,
  géige: true,
  géill: true,
  géime: true,
  géine: true,
  geire: true,
  géire: true,
  geirí: true,
  géirí: true,
  geirm: true,
  geise: true,
  géise: true,
  geite: true,
  geití: true,
  Geoff: true,
  geoga: true,
  geoin: true,
  geois: true,
  Gerry: true,
  Getty: true,
  ghabh: true,
  ghábh: true,
  ghada: true,
  Ghael: true,
  ghafa: true,
  ghága: true,
  ghaid: true,
  ghail: true,
  ghaín: true,
  ghair: true,
  gháir: true,
  ghais: true,
  gháis: true,
  ghala: true,
  ghála: true,
  ghalf: true,
  Ghall: true,
  ghalú: true,
  gháma: true,
  Ghána: true,
  ghang: true,
  ghann: true,
  ghaol: true,
  ghara: true,
  gharg: true,
  gharr: true,
  ghású: true,
  gheab: true,
  gheac: true,
  gheaf: true,
  ghéag: true,
  gheal: true,
  gheam: true,
  ghean: true,
  ghéar: true,
  gheas: true,
  ghéim: true,
  ghéin: true,
  gheir: true,
  ghéir: true,
  gheis: true,
  ghéis: true,
  gheit: true,
  ghiar: true,
  ghige: true,
  ghíge: true,
  ghild: true,
  ghile: true,
  ghill: true,
  ghimp: true,
  ghine: true,
  ghiní: true,
  ghiob: true,
  ghíoc: true,
  ghíog: true,
  ghlab: true,
  ghlac: true,
  ghlae: true,
  ghlam: true,
  ghlám: true,
  ghlan: true,
  ghlao: true,
  ghlár: true,
  ghlas: true,
  ghleo: true,
  ghlib: true,
  ghlic: true,
  ghlig: true,
  ghliú: true,
  ghlób: true,
  ghlór: true,
  ghlug: true,
  ghlún: true,
  ghnás: true,
  ghoba: true,
  ghoib: true,
  ghoic: true,
  ghoid: true,
  ghoil: true,
  ghoin: true,
  ghoir: true,
  Gholl: true,
  ghona: true,
  ghonc: true,
  ghora: true,
  ghorb: true,
  ghorm: true,
  ghort: true,
  ghrád: true,
  ghrae: true,
  ghraf: true,
  ghrág: true,
  ghraí: true,
  ghram: true,
  ghrán: true,
  ghríl: true,
  ghrin: true,
  ghrís: true,
  ghrod: true,
  ghroí: true,
  ghrua: true,
  ghrúm: true,
  ghrus: true,
  ghrús: true,
  ghual: true,
  Ghuam: true,
  ghúil: true,
  ghuím: true,
  Ghúim: true,
  ghúim: true,
  ghuma: true,
  ghúna: true,
  ghurd: true,
  ghúrú: true,
  ghusa: true,
  ghuta: true,
  ghúta: true,
  ghuth: true,
  giair: true,
  giall: true,
  Gibbs: true,
  gibir: true,
  gifte: true,
  giftí: true,
  Giles: true,
  gilín: true,
  ginid: true,
  ginim: true,
  ginte: true,
  gíoca: true,
  gíoga: true,
  giolc: true,
  giomp: true,
  giota: true,
  giotú: true,
  gipis: true,
  gírle: true,
  girsí: true,
  giúis: true,
  glaca: true,
  glain: true,
  gláir: true,
  glais: true,
  glamh: true,
  glámh: true,
  glana: true,
  glasa: true,
  gleag: true,
  glean: true,
  gléas: true,
  gléib: true,
  gleic: true,
  gléis: true,
  Glenn: true,
  glibe: true,
  glice: true,
  glinn: true,
  gliog: true,
  gliúc: true,
  Glóir: true,
  glóir: true,
  glonn: true,
  glórú: true,
  glota: true,
  gluig: true,
  glúin: true,
  Glynn: true,
  gnách: true,
  gnáis: true,
  gnaoi: true,
  gnása: true,
  gnáth: true,
  gnéas: true,
  gnéis: true,
  gnímh: true,
  gnúis: true,
  gobaí: true,
  Gobán: true,
  gobán: true,
  gobóg: true,
  gocaí: true,
  Godot: true,
  gófar: true,
  gogaí: true,
  gogal: true,
  Gogan: true,
  gogán: true,
  Góibí: true,
  goice: true,
  goile: true,
  goilí: true,
  goill: true,
  goimh: true,
  goinc: true,
  goiní: true,
  goirb: true,
  góire: true,
  góirí: true,
  goirm: true,
  goirt: true,
  gológ: true,
  gónad: true,
  gonta: true,
  Goode: true,
  goraí: true,
  góraí: true,
  gorán: true,
  gorma: true,
  gormú: true,
  Gorta: true,
  gorta: true,
  gortú: true,
  gorún: true,
  gósta: true,
  Gotaí: true,
  gotaí: true,
  gotha: true,
  Gough: true,
  Gould: true,
  Gowdy: true,
  Grace: true,
  grách: true,
  Gráda: true,
  grádh: true,
  grádú: true,
  Grady: true,
  grága: true,
  gráid: true,
  graif: true,
  gráig: true,
  graim: true,
  gráim: true,
  gráin: true,
  gránn: true,
  Grant: true,
  gránú: true,
  grást: true,
  gráta: true,
  gread: true,
  grean: true,
  gréas: true,
  Green: true,
  Greer: true,
  Gregg: true,
  Gréig: true,
  greim: true,
  gréin: true,
  grian: true,
  gríle: true,
  grinn: true,
  griog: true,
  gríos: true,
  grísc: true,
  groda: true,
  groid: true,
  gróig: true,
  grósa: true,
  gruaí: true,
  gruán: true,
  grúdú: true,
  gruig: true,
  grúim: true,
  gruis: true,
  grúis: true,
  grúpa: true,
  gruth: true,
  guaig: true,
  guail: true,
  guaim: true,
  Guáin: true,
  guais: true,
  Guala: true,
  gualú: true,
  guanó: true,
  gubha: true,
  gudaí: true,
  gúgán: true,
  Guido: true,
  guigh: true,
  guígí: true,
  Guill: true,
  Guine: true,
  guine: true,
  Guiní: true,
  guird: true,
  guití: true,
  gúlaí: true,
  gumaí: true,
  gúnaí: true,
  gúnga: true,
  gunna: true,
  Gupta: true,
  gurab: true,
  gurbh: true,
  gusta: true,
  gutaí: true,
  gutha: true,
  guthú: true,
  Gwynn: true,
  habaí: true,
  habal: true,
  habar: true,
  hadac: true,
  hagaí: true,
  hágar: true,
  hagra: true,
  hagús: true,
  haibí: true,
  haice: true,
  haicí: true,
  háidh: true,
  haife: true,
  Háige: true,
  haigh: true,
  háigh: true,
  hailb: true,
  haill: true,
  hailm: true,
  hailp: true,
  hAilt: true,
  hailt: true,
  háilt: true,
  hÁine: true,
  hainm: true,
  haint: true,
  hairc: true,
  hÁirc: true,
  háirc: true,
  hAird: true,
  haird: true,
  hAire: true,
  haire: true,
  hairg: true,
  hairí: true,
  hAirm: true,
  hairm: true,
  hAirt: true,
  hairt: true,
  háisc: true,
  haise: true,
  hÁise: true,
  háise: true,
  háite: true,
  háith: true,
  Háítí: true,
  hÁivé: true,
  halam: true,
  halfa: true,
  halga: true,
  halla: true,
  hAlpa: true,
  halta: true,
  hálta: true,
  haltú: true,
  halúm: true,
  hamas: true,
  hámóg: true,
  Hanáí: true,
  hanam: true,
  hanas: true,
  hanás: true,
  hanfa: true,
  hanla: true,
  hánra: true,
  hanró: true,
  hansa: true,
  hAodh: true,
  haodh: true,
  haoil: true,
  haoin: true,
  haoir: true,
  hAois: true,
  haois: true,
  haonú: true,
  haosa: true,
  haosú: true,
  Hapaí: true,
  hápaí: true,
  haraí: true,
  háram: true,
  harán: true,
  haras: true,
  háras: true,
  harda: true,
  hardú: true,
  Hardy: true,
  Harry: true,
  hársa: true,
  Harte: true,
  hasal: true,
  háscú: true,
  hasma: true,
  hAsór: true,
  haspa: true,
  hastú: true,
  hataí: true,
  hatar: true,
  Határ: true,
  Hatch: true,
  hátha: true,
  hatit: true,
  hatóg: true,
  Haváí: true,
  Hayes: true,
  heach: true,
  heacú: true,
  héada: true,
  héadh: true,
  héafá: true,
  héaga: true,
  hEala: true,
  heala: true,
  héalú: true,
  Healy: true,
  héamh: true,
  héamú: true,
  heang: true,
  Heany: true,
  hEara: true,
  hEára: true,
  Héara: true,
  hearb: true,
  hearc: true,
  héard: true,
  hearr: true,
  heasa: true,
  héasc: true,
  Heath: true,
  Hegel: true,
  Hehir: true,
  Héibé: true,
  heich: true,
  héide: true,
  Heidi: true,
  héidí: true,
  héigh: true,
  héigí: true,
  heilc: true,
  heile: true,
  héill: true,
  héimh: true,
  heirc: true,
  heire: true,
  Héire: true,
  hÉire: true,
  heirí: true,
  héirí: true,
  heisc: true,
  hÉisc: true,
  héisc: true,
  heisí: true,
  héist: true,
  heite: true,
  héite: true,
  heití: true,
  heits: true,
  Helen: true,
  hEmer: true,
  Heney: true,
  Henri: true,
  Henry: true,
  hEoin: true,
  heoró: true,
  hiadh: true,
  hiain: true,
  hiall: true,
  hiamb: true,
  hiamh: true,
  hianú: true,
  hiaró: true,
  hiarr: true,
  hiasc: true,
  hiata: true,
  hIáva: true,
  hibhe: true,
  híbis: true,
  hidil: true,
  hídiú: true,
  higín: true,
  Hilda: true,
  hílim: true,
  híliú: true,
  himir: true,
  himní: true,
  himpí: true,
  hinia: true,
  hInid: true,
  hinír: true,
  hInis: true,
  hinis: true,
  hinne: true,
  hinní: true,
  hInse: true,
  hinse: true,
  hinsí: true,
  hintí: true,
  híoca: true,
  híola: true,
  híona: true,
  hionú: true,
  híonú: true,
  hiora: true,
  hÍosa: true,
  hióta: true,
  híota: true,
  hioth: true,
  hiris: true,
  hísil: true,
  hísle: true,
  hithe: true,
  hítim: true,
  Hitis: true,
  hIúil: true,
  hiúil: true,
  hiúir: true,
  Hobbs: true,
  hócam: true,
  hócar: true,
  hocas: true,
  hocht: true,
  hócht: true,
  Hodge: true,
  Hogan: true,
  hógha: true,
  hógra: true,
  hoide: true,
  hóide: true,
  hoidí: true,
  hÓige: true,
  hóige: true,
  hoilc: true,
  hoirc: true,
  hOird: true,
  hoird: true,
  holaí: true,
  hólaí: true,
  holam: true,
  holar: true,
  holla: true,
  Holly: true,
  hológ: true,
  hólta: true,
  hÓmaí: true,
  hOman: true,
  hómar: true,
  Homer: true,
  hómós: true,
  hómra: true,
  Honda: true,
  hopaí: true,
  hópal: true,
  Horan: true,
  Horás: true,
  Hóras: true,
  hordú: true,
  hórga: true,
  horla: true,
  horlú: true,
  Horne: true,
  hornú: true,
  hosán: true,
  hOsló: true,
  hosna: true,
  hósta: true,
  huaim: true,
  hUain: true,
  huain: true,
  huair: true,
  hualú: true,
  huama: true,
  hucht: true,
  húdaí: true,
  húdar: true,
  hUíbh: true,
  huíbh: true,
  huídh: true,
  huige: true,
  huigí: true,
  huile: true,
  húill: true,
  húire: true,
  húirí: true,
  húisc: true,
  hÚlla: true,
  húlla: true,
  hulna: true,
  hulóg: true,
  humar: true,
  húmas: true,
  humha: true,
  hunsa: true,
  hupaí: true,
  húpas: true,
  hurla: true,
  hurra: true,
  hurrú: true,
  husár: true,
  hútha: true,
  Hynes: true,
  iadar: true,
  iafaí: true,
  iafar: true,
  iaigh: true,
  iaigí: true,
  Iailé: true,
  iaimh: true,
  iainn: true,
  iaití: true,
  Iáivé: true,
  Ialás: true,
  ialus: true,
  iamar: true,
  iamba: true,
  ianaí: true,
  Ianna: true,
  Iarla: true,
  iarla: true,
  iarnú: true,
  iaróg: true,
  iaróí: true,
  Iaróm: true,
  iarra: true,
  iarta: true,
  iasca: true,
  Iasón: true,
  iataí: true,
  iatán: true,
  Iávaí: true,
  Ibéir: true,
  ibhfí: true,
  ibhim: true,
  ibhtí: true,
  ibigh: true,
  íbise: true,
  íbisí: true,
  ícigh: true,
  Idaho: true,
  idéal: true,
  idéil: true,
  ídigh: true,
  ídígí: true,
  idile: true,
  idilí: true,
  ídínn: true,
  ídítí: true,
  igíní: true,
  iléam: true,
  iléim: true,
  íligh: true,
  ílígí: true,
  ílínn: true,
  ílítí: true,
  imigh: true,
  imígí: true,
  imill: true,
  imínn: true,
  imirc: true,
  imire: true,
  imirt: true,
  imítí: true,
  impím: true,
  IMRAM: true,
  imrím: true,
  imris: true,
  Iñaki: true,
  inarb: true,
  India: true,
  infir: true,
  ingir: true,
  ingne: true,
  ingní: true,
  Inide: true,
  iníne: true,
  iníon: true,
  iníor: true,
  inite: true,
  inlím: true,
  inlín: true,
  inmhe: true,
  inmhí: true,
  inniu: true,
  insím: true,
  insín: true,
  inslí: true,
  inste: true,
  insúl: true,
  Intel: true,
  inter: true,
  intrí: true,
  íocaí: true,
  íocfá: true,
  Iocht: true,
  Íocht: true,
  íocón: true,
  íoctá: true,
  Íodan: true,
  íogán: true,
  íoglú: true,
  íolaí: true,
  Iolar: true,
  iolar: true,
  iolra: true,
  iolrú: true,
  íoltí: true,
  iomad: true,
  iomaí: true,
  iomas: true,
  íomhá: true,
  iompú: true,
  iomrá: true,
  Ionad: true,
  ionad: true,
  ionaí: true,
  Iónaí: true,
  íonaí: true,
  ionam: true,
  ionar: true,
  ionas: true,
  ionat: true,
  ionga: true,
  ionla: true,
  ionsá: true,
  ionsú: true,
  iontu: true,
  ioraí: true,
  íoraí: true,
  íorna: true,
  Iorua: true,
  íosfá: true,
  iosta: true,
  íosta: true,
  íotaí: true,
  ireas: true,
  Irene: true,
  irise: true,
  irisí: true,
  Irwin: true,
  Isaac: true,
  íseal: true,
  Iseas: true,
  isird: true,
  íslím: true,
  ísliú: true,
  ispín: true,
  istír: true,
  itear: true,
  ithim: true,
  ithir: true,
  ítime: true,
  ítimí: true,
  Iúdás: true,
  lábaí: true,
  lábán: true,
  lacha: true,
  lácha: true,
  lacht: true,
  ladar: true,
  ládar: true,
  Laden: true,
  ladús: true,
  láfaí: true,
  láfar: true,
  lagaí: true,
  Lagan: true,
  Lagán: true,
  lagar: true,
  lágar: true,
  Lagos: true,
  láibe: true,
  laige: true,
  láigh: true,
  laigí: true,
  láigí: true,
  láimh: true,
  láine: true,
  Laing: true,
  lainn: true,
  láinn: true,
  laíon: true,
  Laird: true,
  láite: true,
  láith: true,
  láití: true,
  Lally: true,
  Lalor: true,
  lámaí: true,
  Lamar: true,
  lámar: true,
  lámha: true,
  lampa: true,
  lánaí: true,
  lánán: true,
  Lanca: true,
  Lance: true,
  langa: true,
  lanna: true,
  lannú: true,
  lansa: true,
  lansú: true,
  lánta: true,
  laoch: true,
  laofa: true,
  Laois: true,
  Láóis: true,
  laoma: true,
  lapaí: true,
  lapóg: true,
  láraí: true,
  Larry: true,
  lásaí: true,
  lasán: true,
  lasca: true,
  lasfá: true,
  lasóg: true,
  lasta: true,
  lastá: true,
  lataí: true,
  Laura: true,
  Lavin: true,
  Layla: true,
  LDSIP: true,
  leaba: true,
  leabú: true,
  leaca: true,
  leacú: true,
  leádh: true,
  léadh: true,
  leafa: true,
  leáfá: true,
  leaga: true,
  Leahy: true,
  leaid: true,
  leáim: true,
  leama: true,
  leamh: true,
  léamh: true,
  leana: true,
  léana: true,
  leang: true,
  leann: true,
  leánn: true,
  léann: true,
  leapa: true,
  learg: true,
  léaró: true,
  Leary: true,
  leasa: true,
  léasa: true,
  leasc: true,
  leasú: true,
  léasú: true,
  leata: true,
  leatá: true,
  leath: true,
  Leech: true,
  Leeds: true,
  Leger: true,
  leibe: true,
  leice: true,
  léice: true,
  leide: true,
  leidí: true,
  léifí: true,
  léige: true,
  Leigh: true,
  léigh: true,
  léigí: true,
  léime: true,
  léimh: true,
  léine: true,
  léinn: true,
  léire: true,
  léirí: true,
  leise: true,
  léise: true,
  leite: true,
  léite: true,
  leith: true,
  léith: true,
  léití: true,
  lenar: true,
  lenár: true,
  Lenin: true,
  leofa: true,
  leoga: true,
  Leoin: true,
  leoin: true,
  leoir: true,
  leomh: true,
  leona: true,
  leora: true,
  leoró: true,
  leota: true,
  Levin: true,
  Lewis: true,
  Leyte: true,
  LFOLO: true,
  liach: true,
  liaga: true,
  liain: true,
  liáin: true,
  liata: true,
  liatá: true,
  liath: true,
  Libby: true,
  Libia: true,
  líbín: true,
  Licia: true,
  Licín: true,
  licín: true,
  Liddy: true,
  Lidia: true,
  Lidis: true,
  lídís: true,
  lífeá: true,
  ligfí: true,
  ligim: true,
  ligin: true,
  ligtí: true,
  Lille: true,
  Lilly: true,
  limfe: true,
  limfí: true,
  límid: true,
  límis: true,
  linbh: true,
  Linda: true,
  linge: true,
  lingí: true,
  líním: true,
  líniú: true,
  Linna: true,
  linne: true,
  línte: true,
  Linux: true,
  líoba: true,
  lioca: true,
  líodh: true,
  líofa: true,
  lioga: true,
  lióga: true,
  líoga: true,
  Líoma: true,
  líoma: true,
  líomh: true,
  líona: true,
  lionn: true,
  líonn: true,
  liopa: true,
  lipid: true,
  liric: true,
  líric: true,
  Lisín: true,
  lisín: true,
  liteá: true,
  litir: true,
  lítir: true,
  lítis: true,
  litrí: true,
  liúdh: true,
  liúfá: true,
  liúim: true,
  liúir: true,
  liúis: true,
  liúit: true,
  liúnn: true,
  liúra: true,
  Lloyd: true,
  lóbaí: true,
  lobha: true,
  locaí: true,
  locar: true,
  lócas: true,
  locfá: true,
  Locha: true,
  locha: true,
  locht: true,
  Locke: true,
  Locky: true,
  locrú: true,
  loctá: true,
  lodán: true,
  lodar: true,
  Logan: true,
  logán: true,
  logha: true,
  loice: true,
  Lóich: true,
  loime: true,
  loine: true,
  loiní: true,
  loinn: true,
  loirg: true,
  loisc: true,
  loise: true,
  lóise: true,
  loite: true,
  loití: true,
  lomaí: true,
  lómaí: true,
  lomán: true,
  lomfá: true,
  lomra: true,
  lomtá: true,
  longa: true,
  lonna: true,
  lonnú: true,
  lonrú: true,
  lonta: true,
  lónta: true,
  lópaí: true,
  lorán: true,
  Lorca: true,
  Loren: true,
  lorga: true,
  Lorna: true,
  losán: true,
  losna: true,
  lotaí: true,
  lottó: true,
  Lotus: true,
  Louis: true,
  Lowry: true,
  luach: true,
  luadh: true,
  luafá: true,
  luail: true,
  luaim: true,
  Luain: true,
  luain: true,
  luáin: true,
  luais: true,
  luamh: true,
  luann: true,
  luasc: true,
  luath: true,
  lúbaí: true,
  lúbán: true,
  lúbfá: true,
  lúbóg: true,
  lúbra: true,
  lúbtá: true,
  Lúcán: true,
  Lucas: true,
  Lúcás: true,
  lucha: true,
  lucht: true,
  lúfar: true,
  lugar: true,
  lúibe: true,
  luibh: true,
  luide: true,
  lúide: true,
  luífí: true,
  luigh: true,
  Luigi: true,
  luígí: true,
  Lúing: true,
  luínn: true,
  lúirí: true,
  luite: true,
  lúith: true,
  luití: true,
  lúman: true,
  lumpa: true,
  Lundy: true,
  Lunny: true,
  lusaí: true,
  lusca: true,
  lusra: true,
  mabóg: true,
  macán: true,
  Macao: true,
  Macha: true,
  macra: true,
  macúl: true,
  madar: true,
  madra: true,
  mágaí: true,
  Magan: true,
  Magee: true,
  magma: true,
  mágra: true,
  Maher: true,
  Mahon: true,
  maide: true,
  maidí: true,
  Maier: true,
  maífí: true,
  maige: true,
  Maigh: true,
  maígh: true,
  maígí: true,
  Máigí: true,
  Mailí: true,
  mailp: true,
  maime: true,
  maímh: true,
  máimh: true,
  Maine: true,
  máine: true,
  máiní: true,
  maínn: true,
  mairc: true,
  maire: true,
  mairg: true,
  Mairí: true,
  Máiri: true,
  Máirí: true,
  mairt: true,
  maisc: true,
  maise: true,
  maisí: true,
  maite: true,
  maíte: true,
  máite: true,
  maith: true,
  maití: true,
  maítí: true,
  Makem: true,
  malaí: true,
  málaí: true,
  Malik: true,
  malla: true,
  mallú: true,
  Malmö: true,
  malra: true,
  Málta: true,
  mamaí: true,
  mámas: true,
  mamat: true,
  manaí: true,
  Mánas: true,
  Mandy: true,
  Manet: true,
  manga: true,
  mangó: true,
  mánla: true,
  manna: true,
  mánna: true,
  Manny: true,
  manta: true,
  mantú: true,
  Manus: true,
  Mánus: true,
  maoil: true,
  maoin: true,
  maoir: true,
  maois: true,
  maola: true,
  maolú: true,
  maoth: true,
  mapaí: true,
  maraí: true,
  marbh: true,
  Marco: true,
  Marcy: true,
  margú: true,
  Maria: true,
  Marie: true,
  Mario: true,
  Marks: true,
  marla: true,
  maróg: true,
  marós: true,
  Marsa: true,
  Marsh: true,
  Márta: true,
  Marty: true,
  marfa: true,
  marún: true,
  masca: true,
  masla: true,
  maslú: true,
  Mason: true,
  mataí: true,
  mátaí: true,
  matal: true,
  matán: true,
  Mater: true,
  matóg: true,
  Méabh: true,
  meádh: true,
  méadú: true,
  meáfá: true,
  meaig: true,
  meáim: true,
  meáin: true,
  meala: true,
  méala: true,
  meall: true,
  méalú: true,
  meana: true,
  meang: true,
  meann: true,
  meánn: true,
  meara: true,
  méara: true,
  mearú: true,
  méarú: true,
  measa: true,
  measc: true,
  meata: true,
  meatá: true,
  meath: true,
  méide: true,
  meige: true,
  meile: true,
  méile: true,
  méilí: true,
  meill: true,
  meilt: true,
  méime: true,
  méine: true,
  méire: true,
  meirg: true,
  méise: true,
  méith: true,
  Memel: true,
  Meoid: true,
  meoin: true,
  méóis: true,
  Merck: true,
  Merle: true,
  Meryl: true,
  Meyer: true,
  mhaca: true,
  mhacá: true,
  mhaig: true,
  mháil: true,
  mhaím: true,
  mhair: true,
  mhais: true,
  mháis: true,
  mhala: true,
  mhála: true,
  mhall: true,
  mháma: true,
  mhámh: true,
  mhamó: true,
  mhana: true,
  mhang: true,
  mhant: true,
  mhaol: true,
  mhaor: true,
  mhaos: true,
  mhapa: true,
  mhara: true,
  mharc: true,
  mharg: true,
  Mhars: true,
  mhart: true,
  mharú: true,
  mhása: true,
  mhasc: true,
  mhata: true,
  mháta: true,
  mhéad: true,
  mheán: true,
  mhear: true,
  mhéar: true,
  mheas: true,
  mhéid: true,
  mheig: true,
  mheil: true,
  mhéim: true,
  mhéin: true,
  mheon: true,
  mhian: true,
  mhias: true,
  Mhicí: true,
  mhíge: true,
  mhíle: true,
  mhílí: true,
  mhill: true,
  mhíme: true,
  mhinc: true,
  mhine: true,
  mhíne: true,
  mhíní: true,
  mhíog: true,
  mhíol: true,
  mhion: true,
  mhire: true,
  mhíre: true,
  mhísc: true,
  mhoca: true,
  mhoch: true,
  mhodh: true,
  mhogh: true,
  mhóid: true,
  Mhoil: true,
  mhoil: true,
  mhóin: true,
  mhóir: true,
  mhois: true,
  mhola: true,
  mholl: true,
  mholt: true,
  mhona: true,
  mhóna: true,
  mhóra: true,
  mhorc: true,
  mhorg: true,
  mhort: true,
  mhosc: true,
  mhóta: true,
  mhuca: true,
  mhúch: true,
  mhufa: true,
  mhuga: true,
  mhuin: true,
  mhúin: true,
  mhuir: true,
  mhúir: true,
  mhúis: true,
  mhumú: true,
  mhúna: true,
  mhusc: true,
  mhúta: true,
  Miami: true,
  mianú: true,
  miasa: true,
  míbhá: true,
  milis: true,
  míliú: true,
  mille: true,
  Millí: true,
  Mills: true,
  milse: true,
  milsí: true,
  mílte: true,
  mímfí: true,
  mímim: true,
  mímtí: true,
  mince: true,
  minic: true,
  minim: true,
  míním: true,
  mínis: true,
  míniú: true,
  Minna: true,
  mínós: true,
  Minsc: true,
  minsí: true,
  mínte: true,
  míoca: true,
  míoga: true,
  miona: true,
  míona: true,
  míoná: true,
  mionn: true,
  Mionó: true,
  mionú: true,
  miorr: true,
  míosa: true,
  miota: true,
  mioth: true,
  miotú: true,
  mírín: true,
  mirle: true,
  mirlí: true,
  Mirny: true,
  mírún: true,
  mísce: true,
  Misha: true,
  misin: true,
  misní: true,
  místá: true,
  miste: true,
  mistí: true,
  Mitch: true,
  mitín: true,
  miúil: true,
  modal: true,
  modha: true,
  modrú: true,
  modúl: true,
  mogal: true,
  mogán: true,
  mogar: true,
  mogha: true,
  Mohan: true,
  móide: true,
  móidí: true,
  moill: true,
  moilt: true,
  moing: true,
  moirc: true,
  Móire: true,
  móire: true,
  móirí: true,
  moirt: true,
  moisc: true,
  moite: true,
  molaí: true,
  molás: true,
  molfá: true,
  Molly: true,
  molta: true,
  moltá: true,
  monad: true,
  monaí: true,
  Monet: true,
  mónóg: true,
  Monty: true,
  Moore: true,
  móraí: true,
  Moran: true,
  mórán: true,
  mórfá: true,
  morga: true,
  mórga: true,
  Morna: true,
  Mórna: true,
  Móróg: true,
  Morse: true,
  mórtá: true,
  mosaí: true,
  mosán: true,
  Moscó: true,
  Moshe: true,
  mótaí: true,
  mótar: true,
  mothú: true,
  múcas: true,
  múcha: true,
  mufaí: true,
  mufal: true,
  mugaí: true,
  muice: true,
  muicí: true,
  múidí: true,
  muine: true,
  múine: true,
  Muing: true,
  muiní: true,
  Muire: true,
  Muirí: true,
  muirí: true,
  muirn: true,
  muisc: true,
  múisc: true,
  muise: true,
  mulla: true,
  mumaí: true,
  Mundy: true,
  múnfá: true,
  múnla: true,
  múnlú: true,
  Munna: true,
  múnta: true,
  múntá: true,
  murab: true,
  Múraí: true,
  múraí: true,
  murar: true,
  musal: true,
  musla: true,
  mútaí: true,
  mútóg: true,
  Nábla: true,
  nádúr: true,
  Naíon: true,
  naíon: true,
  náire: true,
  náirí: true,
  naisc: true,
  naofa: true,
  Naois: true,
  nAois: true,
  naomh: true,
  Naomi: true,
  nárab: true,
  nárbh: true,
  natha: true,
  neach: true,
  neadú: true,
  Neain: true,
  néalú: true,
  neamh: true,
  Neans: true,
  Néaró: true,
  neart: true,
  Neasa: true,
  neasa: true,
  néata: true,
  neide: true,
  neoid: true,
  neoin: true,
  niamh: true,
  nIáva: true,
  nicil: true,
  nídís: true,
  nífeá: true,
  Nigel: true,
  Nígir: true,
  Niles: true,
  nílid: true,
  nílim: true,
  nimfí: true,
  nimhe: true,
  nimhí: true,
  nímid: true,
  nímis: true,
  níodh: true,
  niomh: true,
  níonn: true,
  niteá: true,
  nithe: true,
  nócha: true,
  nocht: true,
  nódaí: true,
  noirm: true,
  nónta: true,
  nótaí: true,
  Notre: true,
  nóvaí: true,
  nuáil: true,
  nUain: true,
  nuair: true,
  nuála: true,
  nuaré: true,
  núdal: true,
  nUíbh: true,
  nuige: true,
  núise: true,
  nutaí: true,
  obach: true,
  obadh: true,
  obaim: true,
  obair: true,
  obann: true,
  obfaí: true,
  obfar: true,
  óbóir: true,
  óbóra: true,
  obtaí: true,
  obtar: true,
  obtha: true,
  ócáid: true,
  ócaim: true,
  ócair: true,
  ochón: true,
  óchta: true,
  ochtó: true,
  ochtú: true,
  ocraí: true,
  ocras: true,
  odhar: true,
  odhra: true,
  Ógáin: true,
  ogham: true,
  Ógras: true,
  oibil: true,
  oibre: true,
  oibrí: true,
  Oíche: true,
  oíche: true,
  oíchí: true,
  oidhe: true,
  oidim: true,
  Óidin: true,
  oidis: true,
  Oifig: true,
  oifig: true,
  oighe: true,
  óighe: true,
  oilce: true,
  oilfí: true,
  oilim: true,
  oille: true,
  oilte: true,
  oiltí: true,
  óinsí: true,
  oirfí: true,
  oirim: true,
  oiris: true,
  oirní: true,
  oirtí: true,
  Oisín: true,
  oisín: true,
  oisre: true,
  oisrí: true,
  oitir: true,
  Oivid: true,
  olach: true,
  óladh: true,
  olaím: true,
  ólaim: true,
  olair: true,
  olann: true,
  ólann: true,
  olcas: true,
  ólfaí: true,
  ólfar: true,
  ollaí: true,
  ollás: true,
  Ollie: true,
  olódh: true,
  olófá: true,
  ológa: true,
  Olsen: true,
  Olson: true,
  óltaí: true,
  óltar: true,
  olúil: true,
  olúla: true,
  ómair: true,
  ómóis: true,
  ómraí: true,
  ónarb: true,
  onóir: true,
  onóra: true,
  onórú: true,
  ópail: true,
  optaí: true,
  óráid: true,
  óraím: true,
  ordaí: true,
  ordóg: true,
  orgán: true,
  orlaí: true,
  ormsa: true,
  ornaí: true,
  óródh: true,
  órófá: true,
  Orpen: true,
  Orson: true,
  ortha: true,
  orthu: true,
  ortsa: true,
  osáin: true,
  ósais: true,
  oscar: true,
  osnaí: true,
  ospís: true,
  Osraí: true,
  óstaí: true,
  óstán: true,
  otair: true,
  othar: true,
  otras: true,
  pabaí: true,
  Pablo: true,
  pacaí: true,
  Padaí: true,
  paeán: true,
  Paidí: true,
  Páidí: true,
  Páile: true,
  páile: true,
  pailm: true,
  Paine: true,
  Páirc: true,
  páirc: true,
  páirt: true,
  Páise: true,
  páise: true,
  paite: true,
  páite: true,
  páité: true,
  Palaí: true,
  pálás: true,
  pánaí: true,
  panda: true,
  panna: true,
  pánna: true,
  paoil: true,
  Pápaí: true,
  pápaí: true,
  papúl: true,
  papús: true,
  Páras: true,
  Parks: true,
  parúl: true,
  pasta: true,
  pasúr: true,
  peaca: true,
  péaca: true,
  peacú: true,
  Peait: true,
  peall: true,
  peann: true,
  peata: true,
  peice: true,
  péice: true,
  Peige: true,
  peige: true,
  Peigí: true,
  Péigí: true,
  peile: true,
  peill: true,
  péine: true,
  péint: true,
  péire: true,
  péirí: true,
  Peirs: true,
  péist: true,
  phaba: true,
  phaca: true,
  Pháil: true,
  pháil: true,
  Pháin: true,
  pháir: true,
  Pháis: true,
  pháis: true,
  Phait: true,
  phait: true,
  pháit: true,
  phána: true,
  phaol: true,
  phaor: true,
  Phápa: true,
  phápa: true,
  phéac: true,
  phéas: true,
  pheic: true,
  Pheig: true,
  pheig: true,
  pheil: true,
  phéin: true,
  phian: true,
  phíbe: true,
  phice: true,
  phíce: true,
  phící: true,
  phíle: true,
  phílí: true,
  phill: true,
  phinc: true,
  phing: true,
  phinn: true,
  phíob: true,
  phioc: true,
  phióg: true,
  phise: true,
  phite: true,
  phlab: true,
  phlac: true,
  phlán: true,
  phlás: true,
  phlob: true,
  phlód: true,
  phluc: true,
  phlúr: true,
  phlus: true,
  phóca: true,
  phóga: true,
  phoic: true,
  phoil: true,
  phóir: true,
  phóit: true,
  Pholl: true,
  pholl: true,
  phóló: true,
  phóna: true,
  phonc: true,
  Phort: true,
  phort: true,
  phórú: true,
  phósa: true,
  phost: true,
  phota: true,
  phrae: true,
  Phrág: true,
  phram: true,
  phrap: true,
  phras: true,
  phrás: true,
  phrós: true,
  phúca: true,
  phúic: true,
  phúir: true,
  phuis: true,
  phulc: true,
  phúma: true,
  phunc: true,
  phunt: true,
  phupa: true,
  phusa: true,
  phuth: true,
  pianó: true,
  piara: true,
  picil: true,
  picim: true,
  pigín: true,
  pigmí: true,
  Pilar: true,
  Pilib: true,
  Pilip: true,
  pílir: true,
  pince: true,
  pinge: true,
  pinse: true,
  pinsí: true,
  píoba: true,
  píobú: true,
  pióga: true,
  píopa: true,
  píosa: true,
  Pisid: true,
  Pitia: true,
  pládh: true,
  plaic: true,
  Plaid: true,
  pláim: true,
  pláin: true,
  pláis: true,
  plait: true,
  Plame: true,
  plána: true,
  planc: true,
  plánn: true,
  plapa: true,
  pláta: true,
  plean: true,
  pléim: true,
  plimp: true,
  plinc: true,
  plódú: true,
  plóid: true,
  plota: true,
  pluca: true,
  plúch: true,
  pluda: true,
  pluga: true,
  pluid: true,
  plúir: true,
  pluis: true,
  pluma: true,
  plúrú: true,
  pobal: true,
  pócaí: true,
  pocán: true,
  pócar: true,
  Pogga: true,
  póige: true,
  Poill: true,
  poill: true,
  poimp: true,
  poinc: true,
  póire: true,
  Poirt: true,
  poirt: true,
  poist: true,
  póite: true,
  póití: true,
  polaí: true,
  polca: true,
  polla: true,
  Polly: true,
  pónaí: true,
  poncú: true,
  Portó: true,
  portú: true,
  pósae: true,
  pósta: true,
  postú: true,
  potaí: true,
  Power: true,
  práib: true,
  praip: true,
  prais: true,
  práis: true,
  prapa: true,
  prasa: true,
  práta: true,
  preab: true,
  preas: true,
  Priam: true,
  prima: true,
  prioc: true,
  Prior: true,
  prios: true,
  Prius: true,
  próca: true,
  profa: true,
  próis: true,
  proit: true,
  promh: true,
  pruic: true,
  Prúis: true,
  prúna: true,
  Pryor: true,
  púcaí: true,
  púcán: true,
  púcóg: true,
  púdal: true,
  púdar: true,
  púdrú: true,
  púice: true,
  púicí: true,
  puinc: true,
  puinn: true,
  puins: true,
  puint: true,
  púire: true,
  puití: true,
  púmaí: true,
  pumpa: true,
  Púnaí: true,
  pupaí: true,
  púraí: true,
  purgú: true,
  púróg: true,
  pusaí: true,
  putha: true,
  putóg: true,
  rábaí: true,
  rábóg: true,
  rabún: true,
  racaí: true,
  rácaí: true,
  racán: true,
  racht: true,
  racún: true,
  radaí: true,
  rádal: true,
  radar: true,
  radfá: true,
  radón: true,
  ráfla: true,
  rafta: true,
  ragaí: true,
  ragús: true,
  ráibe: true,
  raibh: true,
  raibí: true,
  raice: true,
  ráige: true,
  ráigí: true,
  railí: true,
  ráiní: true,
  rainn: true,
  ráite: true,
  ráití: true,
  rálaí: true,
  rálóg: true,
  Ralph: true,
  ramaí: true,
  ramás: true,
  rámha: true,
  rampa: true,
  ránaí: true,
  ranga: true,
  rangú: true,
  ranna: true,
  ransú: true,
  raoin: true,
  rapaí: true,
  rapán: true,
  rapar: true,
  rásaí: true,
  raspa: true,
  rásúr: true,
  rátaí: true,
  ratán: true,
  ratha: true,
  rátha: true,
  rathú: true,
  ráthú: true,
  Rawls: true,
  réaba: true,
  reaca: true,
  réada: true,
  réadú: true,
  réala: true,
  réalt: true,
  réama: true,
  reang: true,
  reann: true,
  recte: true,
  recto: true,
  Reese: true,
  réibh: true,
  reice: true,
  réice: true,
  Reich: true,
  réicí: true,
  réidh: true,
  réime: true,
  réimí: true,
  Réine: true,
  réire: true,
  réisc: true,
  réise: true,
  réisí: true,
  reite: true,
  réite: true,
  reith: true,
  reití: true,
  réití: true,
  reoán: true,
  reoch: true,
  reodh: true,
  reofá: true,
  reoim: true,
  réóin: true,
  reonn: true,
  Riabh: true,
  riabh: true,
  Riach: true,
  riach: true,
  Riada: true,
  riail: true,
  Riain: true,
  riain: true,
  rialú: true,
  riamh: true,
  rianú: true,
  riara: true,
  riasc: true,
  riast: true,
  ribín: true,
  ricil: true,
  ricle: true,
  riclí: true,
  ricne: true,
  rídís: true,
  rífeá: true,
  rífir: true,
  ríghe: true,
  rigín: true,
  riglí: true,
  rímid: true,
  rímis: true,
  rince: true,
  rincí: true,
  rincs: true,
  rinne: true,
  rinní: true,
  rinse: true,
  rinsí: true,
  ríodh: true,
  ríofa: true,
  ríoga: true,
  ríomh: true,
  ríona: true,
  ríonn: true,
  ríora: true,
  rísín: true,
  ríste: true,
  rístí: true,
  riteá: true,
  rithe: true,
  ríthe: true,
  ríúil: true,
  ríúla: true,
  róbaí: true,
  rocaí: true,
  rocán: true,
  rocfá: true,
  roctá: true,
  ródaí: true,
  Ródas: true,
  rodta: true,
  ródta: true,
  rogha: true,
  roide: true,
  Róigh: true,
  roimh: true,
  Róimh: true,
  Róine: true,
  roinn: true,
  roisc: true,
  roise: true,
  roisí: true,
  rolla: true,
  rollú: true,
  rompu: true,
  rondó: true,
  ronna: true,
  rónna: true,
  rónóg: true,
  rónta: true,
  rópaí: true,
  ropfá: true,
  ropóg: true,
  roptá: true,
  rosaí: true,
  rósaí: true,
  rosán: true,
  rosca: true,
  rósóg: true,
  rosta: true,
  rósta: true,
  róstá: true,
  rótaí: true,
  rótar: true,
  rotha: true,
  ruadh: true,
  ruafá: true,
  ruaig: true,
  ruaim: true,
  ruáin: true,
  ruais: true,
  ruann: true,
  rúbal: true,
  rubar: true,
  rubha: true,
  rúbóg: true,
  rúcán: true,
  rudaí: true,
  rufaí: true,
  rugaí: true,
  ruibh: true,
  rúide: true,
  rúipí: true,
  rúisc: true,
  ruise: true,
  Rúise: true,
  Rúisí: true,
  rumaí: true,
  rumba: true,
  rumpa: true,
  rúnaí: true,
  rúnda: true,
  runga: true,
  runta: true,
  ruóga: true,
  rúpaí: true,
  rúraí: true,
  Russo: true,
  rútaí: true,
  Ryder: true,
  sábha: true,
  sacán: true,
  sacar: true,
  sacfá: true,
  Sachs: true,
  sactá: true,
  Sádaí: true,
  sádaí: true,
  sádar: true,
  sáfaí: true,
  sáfar: true,
  ságaí: true,
  saíán: true,
  sáibh: true,
  Saící: true,
  Saidí: true,
  sáigh: true,
  sáigí: true,
  Saile: true,
  saile: true,
  sáile: true,
  sailí: true,
  saill: true,
  sailm: true,
  sáimh: true,
  sainí: true,
  sáinn: true,
  saint: true,
  Sairc: true,
  saird: true,
  Sáíre: true,
  saise: true,
  sáite: true,
  sáith: true,
  sáití: true,
  salaí: true,
  salán: true,
  Salle: true,
  Sally: true,
  sálóg: true,
  salón: true,
  salún: true,
  sámar: true,
  sambó: true,
  sámha: true,
  sanas: true,
  santú: true,
  saobh: true,
  saofa: true,
  saoil: true,
  saoir: true,
  saolú: true,
  saora: true,
  saoth: true,
  sapar: true,
  Sarah: true,
  sáraí: true,
  sásaí: true,
  sásar: true,
  Sasha: true,
  sásra: true,
  sásta: true,
  Saváí: true,
  scafa: true,
  scaga: true,
  scaid: true,
  scaif: true,
  scáil: true,
  scáin: true,
  scaip: true,
  scair: true,
  scala: true,
  scála: true,
  scall: true,
  scálú: true,
  scamh: true,
  scana: true,
  scaob: true,
  scara: true,
  scata: true,
  scáta: true,
  scáth: true,
  sceab: true,
  scead: true,
  scéal: true,
  scean: true,
  scéil: true,
  scéim: true,
  scéin: true,
  sceir: true,
  sceon: true,
  scian: true,
  sciar: true,
  scibe: true,
  scide: true,
  scidí: true,
  scige: true,
  scile: true,
  scime: true,
  scimí: true,
  scinc: true,
  scine: true,
  scinn: true,
  sciob: true,
  sciot: true,
  scíth: true,
  sciúg: true,
  sciúr: true,
  sclár: true,
  sclog: true,
  scóid: true,
  scóig: true,
  scoil: true,
  scóip: true,
  scoir: true,
  scóir: true,
  scoit: true,
  scola: true,
  scóla: true,
  scolb: true,
  scóna: true,
  scora: true,
  scóra: true,
  scorn: true,
  scoth: true,
  scríd: true,
  scrín: true,
  scriú: true,
  scrúd: true,
  scuab: true,
  scuad: true,
  scuid: true,
  scúip: true,
  scúnc: true,
  seaca: true,
  seach: true,
  seada: true,
  séada: true,
  seadú: true,
  seaga: true,
  seaic: true,
  seáil: true,
  seala: true,
  séala: true,
  seall: true,
  séalú: true,
  seama: true,
  séama: true,
  seamú: true,
  seana: true,
  séana: true,
  seang: true,
  seans: true,
  séans: true,
  searc: true,
  searg: true,
  searr: true,
  seasa: true,
  seasc: true,
  séibe: true,
  Seice: true,
  Séich: true,
  Seicí: true,
  seict: true,
  seide: true,
  séide: true,
  seift: true,
  seile: true,
  seilf: true,
  seilg: true,
  seilí: true,
  séimh: true,
  Séimí: true,
  Séine: true,
  séine: true,
  seinm: true,
  seinn: true,
  seire: true,
  séire: true,
  séirí: true,
  seisc: true,
  seise: true,
  séise: true,
  seisí: true,
  séisí: true,
  seoch: true,
  seoda: true,
  seoid: true,
  seoil: true,
  seola: true,
  sféar: true,
  sféir: true,
  shábh: true,
  shaca: true,
  shách: true,
  shádh: true,
  sháfa: true,
  sháfá: true,
  shága: true,
  shaic: true,
  sháig: true,
  shail: true,
  sháil: true,
  sháim: true,
  sháir: true,
  Sháír: true,
  shais: true,
  sháis: true,
  shála: true,
  shalm: true,
  shalú: true,
  shámh: true,
  Shamó: true,
  shann: true,
  shánn: true,
  shaoi: true,
  shaol: true,
  shaor: true,
  Sharp: true,
  shárú: true,
  Shawn: true,
  sheac: true,
  shead: true,
  shéad: true,
  sheal: true,
  sheál: true,
  sheam: true,
  shean: true,
  Sheán: true,
  shéan: true,
  sheáp: true,
  sheas: true,
  sheat: true,
  Sheic: true,
  sheic: true,
  sheid: true,
  shéid: true,
  Shéin: true,
  shéin: true,
  sheir: true,
  shéis: true,
  sheit: true,
  Shell: true,
  sheod: true,
  sheol: true,
  shiad: true,
  Shiam: true,
  shian: true,
  shián: true,
  shíbe: true,
  shícé: true,
  shící: true,
  shíge: true,
  Shile: true,
  shile: true,
  Shíle: true,
  shíle: true,
  Shilí: true,
  shinc: true,
  shine: true,
  Shíne: true,
  shíne: true,
  shiní: true,
  Shíní: true,
  shíní: true,
  Shinn: true,
  shíob: true,
  shioc: true,
  shióg: true,
  shíog: true,
  shíol: true,
  Shión: true,
  shíon: true,
  Shior: true,
  shíor: true,
  shipe: true,
  shíre: true,
  shirg: true,
  shíte: true,
  shíth: true,
  shítí: true,
  shiúl: true,
  shiún: true,
  Shiúr: true,
  shiúr: true,
  shiút: true,
  shlac: true,
  shlad: true,
  shlám: true,
  shlán: true,
  Shlat: true,
  shlat: true,
  shleá: true,
  shlim: true,
  shlip: true,
  shlis: true,
  shliú: true,
  shloc: true,
  shlog: true,
  shlóg: true,
  shlua: true,
  shnab: true,
  shnag: true,
  shnap: true,
  shnas: true,
  shneá: true,
  shním: true,
  shnoí: true,
  shnua: true,
  shóch: true,
  shódh: true,
  shófá: true,
  shoic: true,
  shóid: true,
  shóil: true,
  shóim: true,
  shoip: true,
  shoir: true,
  shona: true,
  shonc: true,
  shonn: true,
  shónn: true,
  shópa: true,
  shorb: true,
  Shore: true,
  shorn: true,
  Short: true,
  shórt: true,
  shosa: true,
  shrac: true,
  shram: true,
  shrán: true,
  shról: true,
  shrón: true,
  shrúb: true,
  shuan: true,
  shuán: true,
  shubh: true,
  shúdh: true,
  shúfá: true,
  shúil: true,
  shuim: true,
  shuím: true,
  shúim: true,
  shúip: true,
  shult: true,
  Shúlú: true,
  shúnn: true,
  shuóg: true,
  shúsa: true,
  shuth: true,
  Siach: true,
  siada: true,
  siáin: true,
  síbín: true,
  Sicil: true,
  sicín: true,
  sifil: true,
  sifín: true,
  sifir: true,
  sigme: true,
  sigmí: true,
  silfí: true,
  sílfí: true,
  silim: true,
  sílim: true,
  silín: true,
  silte: true,
  sílte: true,
  siltí: true,
  síltí: true,
  Simms: true,
  Simon: true,
  since: true,
  sincí: true,
  sínfí: true,
  Singh: true,
  sínim: true,
  síním: true,
  Sínis: true,
  sínis: true,
  síniú: true,
  sinne: true,
  sínte: true,
  síntí: true,
  síoba: true,
  sioca: true,
  síoda: true,
  sióga: true,
  síoga: true,
  síogú: true,
  Sióin: true,
  siolp: true,
  síolú: true,
  siopa: true,
  síora: true,
  siorc: true,
  síorú: true,
  siosa: true,
  siosc: true,
  siota: true,
  síota: true,
  SIPTU: true,
  Siria: true,
  Síris: true,
  síthe: true,
  sithí: true,
  síthí: true,
  sitím: true,
  siúil: true,
  siúit: true,
  siúla: true,
  siúnt: true,
  Skype: true,
  slaba: true,
  slaca: true,
  slada: true,
  slaig: true,
  sláin: true,
  slais: true,
  sláma: true,
  slána: true,
  slánú: true,
  slaod: true,
  slata: true,
  sleán: true,
  sléim: true,
  slige: true,
  sligí: true,
  slime: true,
  slinn: true,
  slíob: true,
  slíoc: true,
  slíom: true,
  slios: true,
  slipe: true,
  slise: true,
  slite: true,
  Sloan: true,
  sloda: true,
  sloga: true,
  slóga: true,
  sloic: true,
  sloig: true,
  slonn: true,
  sluga: true,
  sluma: true,
  slúpa: true,
  smáil: true,
  smálú: true,
  smear: true,
  sméar: true,
  sméid: true,
  smide: true,
  smige: true,
  smiog: true,
  smior: true,
  smiot: true,
  smíst: true,
  Smith: true,
  smóil: true,
  smola: true,
  smuga: true,
  smúir: true,
  smuit: true,
  smúit: true,
  smúra: true,
  smuta: true,
  smutá: true,
  Smyth: true,
  snaba: true,
  snáfa: true,
  snaga: true,
  snámh: true,
  snapa: true,
  snasa: true,
  snasú: true,
  snáth: true,
  sneap: true,
  snífí: true,
  snige: true,
  snigh: true,
  snígí: true,
  snínn: true,
  sniog: true,
  snite: true,
  snití: true,
  snoím: true,
  snúda: true,
  sobal: true,
  socaí: true,
  socán: true,
  sócha: true,
  socht: true,
  socra: true,
  socrú: true,
  sócúl: true,
  sodar: true,
  sódar: true,
  sodóg: true,
  sófaí: true,
  sófar: true,
  sóide: true,
  sóigh: true,
  sóigí: true,
  sóile: true,
  sóilí: true,
  soinn: true,
  sóinn: true,
  soirb: true,
  soirn: true,
  sóirt: true,
  sóite: true,
  soith: true,
  sóití: true,
  solad: true,
  solas: true,
  sólás: true,
  sólfá: true,
  sómar: true,
  sómas: true,
  somóg: true,
  Sonaí: true,
  sonaí: true,
  sonas: true,
  sonda: true,
  Sonia: true,
  sónna: true,
  Sonny: true,
  sonóg: true,
  sonra: true,
  sonrú: true,
  sopóg: true,
  során: true,
  Soros: true,
  sotal: true,
  sotar: true,
  Sótas: true,
  sóúil: true,
  sóúla: true,
  South: true,
  spáda: true,
  spaga: true,
  spága: true,
  spaid: true,
  spáis: true,
  spall: true,
  spalp: true,
  spang: true,
  sparr: true,
  speal: true,
  spear: true,
  speár: true,
  speic: true,
  spéic: true,
  speig: true,
  speir: true,
  spéir: true,
  spéis: true,
  spíce: true,
  spící: true,
  spíde: true,
  spídí: true,
  spíle: true,
  spílí: true,
  spíne: true,
  spíon: true,
  spior: true,
  spleá: true,
  splín: true,
  spóca: true,
  spoch: true,
  Spock: true,
  spóil: true,
  spoir: true,
  spóir: true,
  spóla: true,
  sponc: true,
  spora: true,
  spórt: true,
  spota: true,
  sprae: true,
  sprid: true,
  spris: true,
  sprís: true,
  sprot: true,
  sprús: true,
  spúit: true,
  sraca: true,
  Sráid: true,
  sráid: true,
  sráin: true,
  srama: true,
  srann: true,
  sraon: true,
  srath: true,
  srian: true,
  sríce: true,
  sríoc: true,
  sróil: true,
  sróna: true,
  sruán: true,
  srúib: true,
  Sruth: true,
  sruth: true,
  stáca: true,
  stada: true,
  stagh: true,
  staic: true,
  staid: true,
  staif: true,
  stail: true,
  stáin: true,
  stair: true,
  stáir: true,
  Stáit: true,
  stáit: true,
  stalc: true,
  stálú: true,
  stána: true,
  stang: true,
  stánú: true,
  staon: true,
  starr: true,
  stéad: true,
  steig: true,
  stéig: true,
  Stein: true,
  steip: true,
  stice: true,
  Stigh: true,
  stile: true,
  stíle: true,
  stílí: true,
  stioc: true,
  stipí: true,
  stiúg: true,
  stobh: true,
  stoca: true,
  stóch: true,
  stoda: true,
  stofa: true,
  stoic: true,
  stoil: true,
  stóil: true,
  stóir: true,
  stoll: true,
  stolp: true,
  Stone: true,
  stopa: true,
  stópa: true,
  storc: true,
  stoth: true,
  Stowe: true,
  strae: true,
  Straw: true,
  strób: true,
  stróc: true,
  strus: true,
  stuca: true,
  stucó: true,
  stuif: true,
  stupa: true,
  suain: true,
  suáin: true,
  Suais: true,
  suarú: true,
  subha: true,
  sucaí: true,
  súdar: true,
  súfaí: true,
  súfar: true,
  súgaí: true,
  súgán: true,
  suífí: true,
  suigh: true,
  súigh: true,
  suígí: true,
  súigí: true,
  súile: true,
  Súilí: true,
  súilí: true,
  Súill: true,
  suilt: true,
  suime: true,
  suímh: true,
  suimí: true,
  suínn: true,
  súinn: true,
  suíóg: true,
  suirí: true,
  suite: true,
  súite: true,
  suití: true,
  súití: true,
  súlán: true,
  sular: true,
  súmar: true,
  súmóg: true,
  súnás: true,
  sunda: true,
  súnna: true,
  suóga: true,
  súrac: true,
  súram: true,
  surda: true,
  súsaí: true,
  Susan: true,
  súsán: true,
  Susie: true,
  sútán: true,
  sútar: true,
  sutha: true,
  sútha: true,
  svuít: true,
  tabhú: true,
  tábla: true,
  tabló: true,
  táblú: true,
  tabúr: true,
  tacaí: true,
  tacar: true,
  tacas: true,
  tAcht: true,
  tacht: true,
  tácla: true,
  tadaí: true,
  Tadhg: true,
  taghd: true,
  tagra: true,
  taifí: true,
  tailc: true,
  tailm: true,
  táimh: true,
  Taimí: true,
  táire: true,
  tairg: true,
  táirg: true,
  táirí: true,
  tairr: true,
  taisc: true,
  táisc: true,
  taise: true,
  taisí: true,
  táite: true,
  tÁivé: true,
  talpa: true,
  tálta: true,
  talún: true,
  támha: true,
  támhú: true,
  Tammi: true,
  Tammy: true,
  Tampa: true,
  tanaí: true,
  tangó: true,
  Tanya: true,
  taobh: true,
  taois: true,
  taoma: true,
  taosc: true,
  taosú: true,
  tapóg: true,
  tarae: true,
  Tarbh: true,
  tarbh: true,
  tarlú: true,
  Tarpy: true,
  tarra: true,
  tarta: true,
  Tartu: true,
  tásca: true,
  tátal: true,
  tátha: true,
  táthú: true,
  Teach: true,
  teach: true,
  téacs: true,
  téada: true,
  téadh: true,
  teaic: true,
  téama: true,
  téamh: true,
  téana: true,
  teann: true,
  téann: true,
  tearc: true,
  teasa: true,
  teasc: true,
  Téibh: true,
  teibí: true,
  Téibí: true,
  téice: true,
  téide: true,
  teidí: true,
  teifí: true,
  téigh: true,
  téigí: true,
  teile: true,
  teilg: true,
  teilí: true,
  téimh: true,
  teipe: true,
  téipe: true,
  teire: true,
  teirt: true,
  téise: true,
  teist: true,
  teite: true,
  téite: true,
  teith: true,
  Tempe: true,
  Terri: true,
  Terry: true,
  Tesco: true,
  Tesla: true,
  Texas: true,
  thaca: true,
  thacú: true,
  thaga: true,
  tháib: true,
  tháil: true,
  tháin: true,
  tháir: true,
  thais: true,
  thála: true,
  thalc: true,
  thall: true,
  thámh: true,
  thána: true,
  thanc: true,
  thanú: true,
  thaom: true,
  thaos: true,
  thapa: true,
  thapú: true,
  tharr: true,
  thart: true,
  thasc: true,
  thásc: true,
  tháth: true,
  thatú: true,
  théac: true,
  théad: true,
  theas: true,
  théim: true,
  theip: true,
  théip: true,
  their: true,
  théis: true,
  thiar: true,
  thigh: true,
  thile: true,
  thíle: true,
  thilí: true,
  thílí: true,
  thíme: true,
  Thímí: true,
  thine: true,
  thinn: true,
  thint: true,
  thíos: true,
  Thíre: true,
  thíre: true,
  thite: true,
  thití: true,
  thiúb: true,
  thiús: true,
  thlás: true,
  thóch: true,
  thofa: true,
  thófú: true,
  thoga: true,
  Thóga: true,
  thóga: true,
  thogh: true,
  thoil: true,
  thoim: true,
  thoin: true,
  thóin: true,
  thoir: true,
  thóir: true,
  thoit: true,
  thola: true,
  tholg: true,
  tholl: true,
  thona: true,
  thóna: true,
  thonn: true,
  thóra: true,
  Thórá: true,
  thorc: true,
  thort: true,
  thost: true,
  thosú: true,
  thrae: true,
  Thraí: true,
  thrál: true,
  thram: true,
  thrap: true,
  threá: true,
  threo: true,
  thric: true,
  Thríú: true,
  thríú: true,
  throí: true,
  throm: true,
  thrón: true,
  thróp: true,
  thrót: true,
  thrua: true,
  thruc: true,
  thrup: true,
  thrus: true,
  thuar: true,
  thuas: true,
  thuga: true,
  thuig: true,
  thuil: true,
  thuin: true,
  thúir: true,
  thúis: true,
  thuma: true,
  thúna: true,
  thura: true,
  thusa: true,
  thúsú: true,
  thuth: true,
  thútú: true,
  tíáil: true,
  tíála: true,
  tiara: true,
  tIáva: true,
  tibhe: true,
  tibia: true,
  Tibir: true,
  tífis: true,
  tigim: true,
  tílím: true,
  tíliú: true,
  Tímín: true,
  Timmy: true,
  tiníl: true,
  tinne: true,
  tinte: true,
  tiomp: true,
  tíopa: true,
  tÍosa: true,
  tirim: true,
  tithe: true,
  titim: true,
  tiúba: true,
  tiubh: true,
  tIúil: true,
  tiúin: true,
  tiúis: true,
  tláis: true,
  tnúth: true,
  tobac: true,
  tobán: true,
  tobar: true,
  Tobin: true,
  Tocco: true,
  tocht: true,
  todóg: true,
  togaí: true,
  togha: true,
  togra: true,
  Tóibí: true,
  toice: true,
  toicí: true,
  toilg: true,
  toill: true,
  toilm: true,
  toinn: true,
  toirc: true,
  toirm: true,
  toirt: true,
  toisc: true,
  toise: true,
  toisí: true,
  toite: true,
  toití: true,
  tolla: true,
  Tomaí: true,
  tomaí: true,
  Tómaí: true,
  tOman: true,
  tomán: true,
  Tomás: true,
  Tommy: true,
  tomóg: true,
  tonaí: true,
  Tónaí: true,
  tónaí: true,
  tónas: true,
  Tonga: true,
  tonna: true,
  tonóg: true,
  tópás: true,
  Toraí: true,
  Tóraí: true,
  tóraí: true,
  tóras: true,
  Torna: true,
  Tórna: true,
  torpa: true,
  torta: true,
  tosaí: true,
  tosca: true,
  toscú: true,
  tOsló: true,
  tosta: true,
  tósta: true,
  tótam: true,
  trach: true,
  trádh: true,
  tráil: true,
  tráim: true,
  trais: true,
  tráma: true,
  tramp: true,
  tránn: true,
  tráta: true,
  tráth: true,
  tréad: true,
  tréan: true,
  treas: true,
  tréas: true,
  tréig: true,
  tréin: true,
  treis: true,
  triad: true,
  trian: true,
  tríbh: true,
  trice: true,
  trína: true,
  trínn: true,
  trioc: true,
  tríom: true,
  tríot: true,
  triuf: true,
  triúr: true,
  triús: true,
  troch: true,
  troda: true,
  troid: true,
  Troim: true,
  tróin: true,
  tróip: true,
  tróit: true,
  troll: true,
  troma: true,
  tromú: true,
  trosc: true,
  trost: true,
  truán: true,
  truas: true,
  Trúda: true,
  truic: true,
  trúig: true,
  truip: true,
  truis: true,
  trunc: true,
  trúpa: true,
  trust: true,
  tuadh: true,
  tuaim: true,
  tuair: true,
  Tuama: true,
  tuama: true,
  tuann: true,
  tuata: true,
  Tuath: true,
  tuath: true,
  túcán: true,
  tufar: true,
  tugaí: true,
  tuige: true,
  tuigí: true,
  tuile: true,
  tuilí: true,
  tuill: true,
  tuine: true,
  tuiní: true,
  Tuirc: true,
  tuire: true,
  túise: true,
  Tulaí: true,
  tulán: true,
  túlán: true,
  tulca: true,
  Tully: true,
  tulra: true,
  túnaí: true,
  Turas: true,
  turas: true,
  Turku: true,
  tútaí: true,
  tútán: true,
  uacht: true,
  uafar: true,
  uafás: true,
  uaibh: true,
  uaidh: true,
  uaigh: true,
  uaill: true,
  uaimh: true,
  uaine: true,
  uainí: true,
  uainn: true,
  uaire: true,
  ualaí: true,
  uamaí: true,
  uamfá: true,
  uamtá: true,
  uanaí: true,
  uanán: true,
  uaraí: true,
  Uarán: true,
  Uasal: true,
  uasal: true,
  uasta: true,
  uatha: true,
  uathu: true,
  ubhaí: true,
  úbhal: true,
  ubhán: true,
  úcadh: true,
  úcaim: true,
  úcann: true,
  úcfaí: true,
  úcfar: true,
  uchta: true,
  uchtú: true,
  úctaí: true,
  úctar: true,
  úctha: true,
  údair: true,
  údarú: true,
  ugach: true,
  uibhe: true,
  uídhe: true,
  Uigín: true,
  uillí: true,
  uimpi: true,
  uinge: true,
  uingí: true,
  uirbí: true,
  úirín: true,
  uisce: true,
  uiscí: true,
  Uladh: true,
  ulcha: true,
  úllaí: true,
  úllóg: true,
  ulnaí: true,
  ulóga: true,
  ulpóg: true,
  Ultaí: true,
  Ultán: true,
  úmadh: true,
  úmaim: true,
  umair: true,
  úmann: true,
  umbar: true,
  úmfaí: true,
  úmfar: true,
  umhaí: true,
  umhal: true,
  umhla: true,
  umhlú: true,
  úmtaí: true,
  úmtar: true,
  úmtha: true,
  ungfá: true,
  ungtá: true,
  unlas: true,
  unsaí: true,
  úpais: true,
  úrach: true,
  Úrail: true,
  uraím: true,
  úraím: true,
  urasú: true,
  urlaí: true,
  urlár: true,
  urnaí: true,
  úrnua: true,
  uródh: true,
  urófá: true,
  urraí: true,
  urróg: true,
  urrús: true,
  ursal: true,
  feilm: true,
}



export default words
